<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>
                    Reports
                    
                </h3>
                
            </div>
        </div>

        <div class="page-body">
            
            <div class="row">
                <div class="col-6">
                    <h4>Fee to be Paid</h4>
                    <form #ffee1="ngForm" (ngSubmit)="feeTobePaid(ffee1.value)" id="formFee1" ngNativeValidate>
                        <div class="row mb-4">
                            <div class="col-6">
                                <label>Select batch</label>
                                <select class="form-control" ngModel name="batch_id" (change)="loadSemesters($event.target.value)" required>
                                    <option *ngFor="let batch of batches" value="{{batch.id}}">{{batch.course_name}} ({{batch.start_year}}) </option>
                                </select>
                            </div>

                            <div class="col-6">
                                <label>Select year</label>
                                <select class="form-control" ngModel name="sem_id" required>
                                    <option *ngFor="let sem of semesters" value="{{sem.id}}">{{sem.name}} </option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <button class="btn btn-sm btn-action" form="formFee1">Download Report</button>

                </div>

                <div class="col-6">
                    <h4>Fee Paid</h4>
                    <form #ffee2="ngForm" (ngSubmit)="feePaid(ffee2.value)" id="formFee2" ngNativeValidate>
                        <div class="row mb-4">
                            <div class="col-6">
                                <label>Select batch</label>
                                <select class="form-control" ngModel name="batch_id" (change)="loadSemesters($event.target.value)" required>
                                    <option *ngFor="let batch of batches" value="{{batch.id}}">{{batch.course_name}} ({{batch.start_year}}) </option>
                                </select>
                            </div>

                            <div class="col-6">
                                <label>Select year</label>
                                <select class="form-control" ngModel name="sem_id" required>
                                    <option *ngFor="let sem of semesters" value="{{sem.id}}">{{sem.name}} </option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <button class="btn btn-sm btn-action" form="formFee2">Download Report</button>

                </div>

            </div>

        </div>
    </div>
</div>