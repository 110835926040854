import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-industrial-visit',
  templateUrl: './industrial-visit.component.html',
  styleUrls: ['./industrial-visit.component.scss']
})
export class IndustrialVisitComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id:any = 0;
  data:any = {title:"", batch:{}};
  loading:any = false;
  newNoteText:any = "";
  notes:any = [];

  selectedPage:any = 1; //1 - notes; 2- students

  students:any = {list:[], count:0};
  limit:any = 30;
  offset:any = 0;
  search:any = "";
  attendance:any = [];
  

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadIV();
    this.loadNotes();
    this.loadAttendance();
  }

  loadIV(){
    this.dataService.getIVSingle(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
        
      }
      
    } );
  }

  loadStudents(batchId){
    this.dataService.getStudents(this.limit, this.offset, this.search, batchId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.students = data;
      }
    } );
  }

  loadAttendance(){
    this.attendance = [];
    this.dataService.getIVAttendance(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.attendance = data.data;
      }
    } ); 
  }

  newNote(data){
    data.iv_id = this.id;
    this.loading = true;
    this.dataService.postIVNote(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        this.newNoteText = "";
        this.loadNotes();

      } else {
        alert(res.msg);
      }

    });
  }

  loadNotes(){
    this.dataService.getIVNotes(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.notes = data.notes;
        
      }
      
    } );
  }

  deleteNote(note){
    var cnf = confirm("Do you really want to delete this note?");

    if(cnf){
      let data = {id:note.id}
      this.dataService.postIVDeleteNote(data).subscribe( (res:any) => {
        
        if(res.status == 0) {
          this.loadNotes();
  
        } else {
          alert(res.msg);
        }
  
      });
    }
  }

  selectPage(page){
    this.selectedPage = page;
    if(page == 2){
      this.loadStudents(this.data.batch.id);
    }
  }

  markAttendance(studentId, attendance){

    if (!this.attendance[studentId]) {
      this.attendance[studentId] = {}; // Create an empty object if it doesn't exist
    }

    let data = {
      iv_id:this.id,
      student_id:studentId,
      attendance:attendance,
      
    }
    this.attendance[studentId] = attendance;
    this.loading = true;
    this.dataService.postIVAttendance(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //
      } else {
        alert(res.msg);
      }

    });
  }

}
