<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Syllabus</h3>
                <p>{{course.name}}</p>
            </div>

            <div class="col-md-6 text-right">
               
            </div>
        </div>

        <div class="page-body">

            <ng-container *ngFor="let semester of data; let i = index">
                <h4 class="mb-4"> Year:  {{semester.name}}</h4>

                <ng-container *ngFor="let topic of semester.syllabus; let j = index">
                    <p class="mb-2 mt-4"> 
                        {{topic.topic}} 
                        &nbsp;
                        <small>
                            <a href="javascript:void(0)" (click)="editTopic(topic)">Edit</a> | <a href="javascript:void(0)" (click)="deleteTopic(topic)">Delete</a>
                            | <a href="#modal-assign-faculty" data-toggle="modal" (click)="selectSubject(topic)">Assign Faculty</a>
                        </small>
                        

                    </p>

                    Faculties: 
                    <span class="faculty-badge" *ngFor="let faculty of topic.faculties">{{faculty.name}}</span>

                    <h6 class="mt-4">
                        MODULES

                        <a href="javascript:void(0);" (click)="addModule(topic)">
                            <small>Add module</small>
                        </a>
                    </h6>

                    <div class="mb-4" *ngFor="let module of topic.modules">
                        <p class="mb-2">
                            {{module.name}}

                            &nbsp;
                            <small>
                                <a href="javascript:void(0)" (click)="editModule(module)">Edit</a> | <a href="javascript:void(0)" (click)="deleteModule(module)">Delete</a>
                                | <a href="#modal-assign-module-faculty" data-toggle="modal" (click)="selectModule(module)">Assign Faculty</a>
                            </small>

                        </p>

                        Module Faculties: 
                        <span class="faculty-badge" *ngFor="let faculty of module.faculties">{{faculty.name}}</span>
                    </div>
                    

                    <hr>
                </ng-container>

                <button class="btn btn-sm btn-warning" (click)="inputTopic(semester.id, i, j)">Add Subject</button>

                <hr>

            </ng-container>

        </div>
    </div>
</div>


<!-- modal -->

<!-- assign topic faculty-->
<div class="modal fade" id="modal-assign-faculty" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Assign faculty to subject {{selectedSubject.topic}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #ffaculty="ngForm" (ngSubmit)="assignFaculty(ffaculty.value)" id="formFaculty" ngNativeValidate>
                <div class="form-group">
                    <label for="">Select Faculty</label>
                    <select ngModel name="faculty_id" class="form-control" required>
                        <option *ngFor="let faculty of faculties" value="{{faculty.id}}">{{faculty.name}}</option>
                    </select>
                </div>

                
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formFaculty" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<!-- assign module faculty-->
<div class="modal fade" id="modal-assign-module-faculty" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Assign faculty to module {{selectedModule.name}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fmfaculty="ngForm" (ngSubmit)="assignModuleFaculty(fmfaculty.value)" id="formModFaculty" ngNativeValidate>
                <div class="form-group">
                    <label for="">Select Faculty</label>
                    <select ngModel name="faculty_id" class="form-control" required>
                        <option *ngFor="let faculty of faculties" value="{{faculty.id}}">{{faculty.name}}</option>
                    </select>
                </div>

                
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formModFaculty" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>