import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  //public REST_API_SERVER = "http://localhost/nipmr-college-api/frontend/web/?r="; //development
  public REST_API_SERVER = "https://nipmr-college-api.metanoa.ai/?r="; //production
  //private SERVER_MAIN = "https://api.metanoa.ai/?r="; //main-app

  constructor(private httpClient: HttpClient) { }

  public login(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/login", data);
  }

  public mainLogin(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/nipmr_login", data);
  }

  public getDefaultPage() {
    return this.httpClient.get(this.REST_API_SERVER + "account/default_page", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public headBar() {
    return this.httpClient.get(this.REST_API_SERVER + "account/headbar", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getUserProfile() {
    return this.httpClient.get(this.REST_API_SERVER + "account/profile", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getUserPhoto() {
    return this.httpClient.get(this.REST_API_SERVER + "account/profile_photo", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getStudentPhoto(id) {
    return this.httpClient.get(this.REST_API_SERVER + "students/profile_photo&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getUserMenu() {
    return this.httpClient.get(this.REST_API_SERVER + "account/menu", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getStudents(limit, offset, search, batch_id, exam_id=0) {
    return this.httpClient.get(this.REST_API_SERVER + "students/list&limit="+limit+"&offset="+offset+"&search="+search+"&batch_id="+batch_id+"&exam_id="+exam_id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getStudent(id) {
    return this.httpClient.get(this.REST_API_SERVER + "students/profile&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getStudentData(id) {
    return this.httpClient.get(this.REST_API_SERVER + "students/full_profile&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getCourses() {
    return this.httpClient.get(this.REST_API_SERVER + "courses/list", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getCourse(id) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/info&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postNewCourse(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/add", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postNewBatch(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/add_batch", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getBatch(id) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/batch&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postNewStudent(data) {
    return this.httpClient.post(this.REST_API_SERVER + "students/add", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getExamTypes() {
    return this.httpClient.get(this.REST_API_SERVER + "exam/types", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getBatches() {
    return this.httpClient.get(this.REST_API_SERVER + "courses/batches", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postNewExam(data) {
    return this.httpClient.post(this.REST_API_SERVER + "exam/add", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteExam(data) {
    return this.httpClient.post(this.REST_API_SERVER + "exam/delete", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postAssignExamSubject(data) {
    return this.httpClient.post(this.REST_API_SERVER + "exam/assign_subject", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getExams(limit, offset, batch_id, type) {
    return this.httpClient.get(this.REST_API_SERVER + "exam/list&limit="+limit+"&offset="+offset+"&batch_id="+batch_id+"&type="+type, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getExam(batch_id, subject_id) {
    return this.httpClient.get(this.REST_API_SERVER + "exam/single&batch_id="+batch_id+"&subject_id="+subject_id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }
  public getExamTotalScore(batch_id, semester_id) {
    return this.httpClient.get(this.REST_API_SERVER + "exam/batch_score&batch_id="+batch_id+"&semester_id="+semester_id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postExamScore(data) {
    return this.httpClient.post(this.REST_API_SERVER + "exam/update_score", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postExamSupplementaryScore(data) {
    return this.httpClient.post(this.REST_API_SERVER + "exam/add_supplementary_score", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postExamName(data) {
    return this.httpClient.post(this.REST_API_SERVER + "exam/update_name", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postExamSettings(data) {
    return this.httpClient.post(this.REST_API_SERVER + "exam/update_settings", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditExamDate(data) {
    return this.httpClient.post(this.REST_API_SERVER + "exam/edit_date", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getStudentScores(studentId) {
    return this.httpClient.get(this.REST_API_SERVER + "exam/student_scores&student_id="+studentId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getFaculties(limit, offset, search) {
    return this.httpClient.get(this.REST_API_SERVER + "faculty/list&limit="+limit+"&offset="+offset+"&search="+search, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getFacultyList() {
    return this.httpClient.get(this.REST_API_SERVER + "faculty/full_list", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getRoles() {
    return this.httpClient.get(this.REST_API_SERVER + "faculty/roles", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postFaculty(data) {
    return this.httpClient.post(this.REST_API_SERVER + "faculty/new_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditFaculty(data) {
    return this.httpClient.post(this.REST_API_SERVER + "faculty/edit_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteFaculty(data) {
    return this.httpClient.post(this.REST_API_SERVER + "faculty/delete_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postFacultyAttendance(data) {
    return this.httpClient.post(this.REST_API_SERVER + "faculty/mark_attendance", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postStudentAttendance(data) {
    return this.httpClient.post(this.REST_API_SERVER + "students/mark_attendance", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postIVAttendance(data) {
    return this.httpClient.post(this.REST_API_SERVER + "others/mark_iv_attendance", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getIVAttendance(id) {
    return this.httpClient.get(this.REST_API_SERVER + "others/load_iv_attendance&iv_id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getFacultyAttendance(date) {
    return this.httpClient.get(this.REST_API_SERVER + "faculty/load_attendance&date="+date, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getStudentsAttendance(batch_id, date) {
    return this.httpClient.get(this.REST_API_SERVER + "students/load_attendance&batch_id="+batch_id+"&date="+date, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postSubjectAttendance(data) {
    return this.httpClient.post(this.REST_API_SERVER + "students/mark_subject_attendance", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getSubjectAttendance(slot_id, date) {
    return this.httpClient.get(this.REST_API_SERVER + "students/load_subject_attendance&slot_id="+slot_id+"&date="+date, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postNewIV(data) {
    return this.httpClient.post(this.REST_API_SERVER + "others/add_iv", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postIVNote(data) {
    return this.httpClient.post(this.REST_API_SERVER + "others/add_iv_note", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postIVDeleteNote(data) {
    return this.httpClient.post(this.REST_API_SERVER + "others/delete_iv_note", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getIVList(limit, offset) {
    return this.httpClient.get(this.REST_API_SERVER + "others/list_iv&limit="+limit+"&offset="+offset, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getIVSingle(id) {
    return this.httpClient.get(this.REST_API_SERVER + "others/view_iv&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getIVNotes(iv_id) {
    return this.httpClient.get(this.REST_API_SERVER + "others/iv_notes&iv_id="+iv_id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getTimeTable(batchId) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/timetable&batch_id="+batchId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getBatchTopics(batchId) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/batch_topics&batch_id="+batchId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postTTSlot(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/update_timetable_slot", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getTTSlot(slotId) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/slot_info&id="+slotId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getSyllabus(courseId) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/syllabus&course_id="+courseId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getSemesterSubjects(semesterId) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/semester_subjects&semester_id="+semesterId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getSemesters(courseId) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/semesters&course_id="+courseId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getBatchSemesters(batchId) {
    return this.httpClient.get(this.REST_API_SERVER + "courses/batch_semesters&batch_id="+batchId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postSyllabusTopic(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/new_syllabus_topic", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditSyllabusTopic(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/edit_syllabus_topic", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteSyllabusTopic(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/delete_syllabus_topic", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditSyllabusModule(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/edit_syllabus_module", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteSyllabusModule(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/delete_syllabus_module", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postAssignSubjectFaculty(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/assign_subject_faculty", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postAssignModuleFaculty(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/assign_module_faculty", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postSubjectModule(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/new_module", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postAssignClassTeacher(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/assign_class_teacher", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postRemoveClassTeacher(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/remove_class_teacher", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditBatch(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/edit_batch", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postUpdateSemester(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/edit_semester", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postNewSemester(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/new_semester", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteSemester(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/delete_semester", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditCourse(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/edit_course", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteCourse(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/delete_course", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postUpdateProfileField(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/update_profile_field", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postPassword(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/update_password", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  /* staff details */
  public getStaffDetails(){
    return this.httpClient.get(this.REST_API_SERVER + "account/load_staff_details&user_id=", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }
  public getStaffDetailsWithId(id){
    return this.httpClient.get(this.REST_API_SERVER + "account/load_staff_details&user_id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postStaffDetails(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/save_staff_details", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  public postDeleteSdMore(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/delete_sd_more", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  public getSdList(){
    return this.httpClient.get(this.REST_API_SERVER + "account/staff_details_list", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postFacultyPhoto(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/upload_photo", data, {
      headers: { 'key': localStorage.getItem("key") },
      reportProgress: true,
    });
  }

  public postStudentPhoto(data) {
    return this.httpClient.post(this.REST_API_SERVER + "students/upload_photo", data, {
      headers: { 'key': localStorage.getItem("key") },
      reportProgress: true,
    });
  }

  public postEditStudent(data) {
    return this.httpClient.post(this.REST_API_SERVER + "students/edit", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  public postUpdateStudent(data) {
    return this.httpClient.post(this.REST_API_SERVER + "students/update_profile", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  public postEditSemesterPeriod(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/update_semester_period", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  public getFeeCategories(){
    return this.httpClient.get(this.REST_API_SERVER + "courses/fee_categories", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getFeeStructure(batchId){
    return this.httpClient.get(this.REST_API_SERVER + "courses/fee_structure&batch_id="+batchId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postUpdateFeeStructure(data) {
    return this.httpClient.post(this.REST_API_SERVER + "courses/update_fee_structure", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  public getFeePayments(studentId){
    return this.httpClient.get(this.REST_API_SERVER + "students/fee_payments&student_id="+studentId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postFeePayment(data) {
    return this.httpClient.post(this.REST_API_SERVER + "students/new_fee_payment", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  public postFPReceiptNo(data) {
    return this.httpClient.post(this.REST_API_SERVER + "students/update_fp_receipt_no", data, {
      headers: { 'key': localStorage.getItem("key") }
    } );
  }

  public getFeePaymentLog(limit, offset, date){
    return this.httpClient.get(this.REST_API_SERVER + "courses/fee_payments&limit="+limit+"&offset="+offset+"&date="+date, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getFeePaidForSemester(batchId, semesterId, studentId){
    return this.httpClient.get(this.REST_API_SERVER + "students/fee_paid_for_semester&batch_id="+batchId+"&semester_id="+semesterId+"&student_id="+studentId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getBatchExams(batchId){
    return this.httpClient.get(this.REST_API_SERVER + "exam/batch_exams&batch_id="+batchId, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getExamScores(batch_id, subject_id, limit, offset, search) {
    return this.httpClient.get(this.REST_API_SERVER + "exam/score&batch_id="+batch_id+"&subject_id="+subject_id+"limit="+limit+"&offset="+offset+"&search="+search, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getStudentExamData(student_id, sem_id) {
    return this.httpClient.get(this.REST_API_SERVER + "exam/student_data&student_id="+student_id+"&sem_id="+sem_id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getGenerateDisposableToken(){
    return this.httpClient.get(this.REST_API_SERVER + "account/generate_disposable_token", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

}
