<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Examinations</h3>
            </div>

            <div class="col-md-2">
                
            </div>
            
            <div class="col-md-2">
                
            </div>

            <div class="col-md-2 text-right">
                
            </div>
        </div>

        <div class="page-body">
            
            <ng-container *ngFor="let year of data">
                <h5 class="mb-4">Year: {{year.name}}</h5>

                <div class="row">
                    <div class="col-4 mb-4" *ngFor="let subject of year.syllabus">
                        <div class="btn-timetable" routerLink="/dashboard/examination/{{batchId}}/{{subject.id}}">
                            <h5>{{subject.topic}}</h5>
                        </div>
                        
                    </div>

                    <div class="col-4 mb-4">
                        <div class="btn-timetable" routerLink="/dashboard/exam-total-score/{{batchId}}/{{year.id}}">
                            <h5>Total</h5>
                        </div>
                        
                    </div>
                </div>

            </ng-container>
            
        </div>
    </div>
</div>

