<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Examination Score</h3>
                <h5>
                    {{data.batchName}}. Year: {{data.semesterName}}
                </h5>
            </div>
        </div>

        <div class="page-body">

            <table class="table table-borderless table-striped">
                <thead>
                    <td>University ID/Reg.No</td>
                    <td>Name</td>
                    <td>Grand total</td>
                    <td>Result</td>
                </thead>

                <tbody>
                    <tr *ngFor="let row of data.data">
                        <td> {{row.university_id}} </td>
                        <td>{{row.student_name}}</td>
                        <td> {{row.total_score}} </td>
                        <td>
                            <span *ngIf="row.passed" class="badge badge-success">PASSED</span>
                            <span *ngIf="!row.passed" class="badge badge-danger">FAILED</span>
                        </td>
                    </tr>
                </tbody>

            </table>

        </div>
    </div>
</div>