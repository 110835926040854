import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-time-tables',
  templateUrl: './time-tables.component.html',
  styleUrls: ['./time-tables.component.scss']
})
export class TimeTablesComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {status:0, courses:[], count:0}
  loading:any = false;

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getCourses().subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
    } );
  }

}
