import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-staff-details',
  templateUrl: './staff-details.component.html',
  styleUrls: ['./staff-details.component.scss']
})
export class StaffDetailsComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute) { }

  user:any = {
    id:0,
    photo_url:0
  };
  photo_url:any = 0;
  dataWhole:any = {user_id:0, name:"", username:""};
  data:any = {}
  more_data:any = [];
  id:any = '0';
  submitting:any  = false;

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    
    if(this.id == null){
      this.id = 0;
    }

    this.loadProfile();
    this.loadData();
  }

  loadProfile(){
    this.dataService.getUserProfile().subscribe( (data: any )=> {
      if(data.status == 0){
        this.user = data;
        if(data.photo_url != null && data.photo_url != ""){
          this.loadUserPhoto();
        }
      }
      
    });
  }

  loadUserPhoto(){
    this.dataService.getUserPhoto().subscribe( (data: any )=> {
      if(data.status == 0){
        this.photo_url = data.photo_url;
        
      }
      
    } );
  }

  loadData(){
    this.dataService.getStaffDetailsWithId(this.id).subscribe( (data: any[] )=> {
      //@ts-ignore
      this.data = data.data;
      this.dataWhole = data;
      //@ts-ignore
      this.more_data = data.more_data;
      
    } );
  }


  submitForm(data){
    this.submitting = true;
    data.more_data = this.more_data;
    data.user_id = this.id;
    this.dataService.postStaffDetails(data).subscribe( (res:any) => {
      this.submitting = false;
      if(res.status == 0) {
        alert(res.msg);
        this.loadData();
      } else {
        alert(res.msg);
      }
    });

  }

  addNew(type){
    
    var newRow = {id:0, type:type, content:""};
    this.more_data[type].push(newRow);
    
  }

  addNewAc(){
    var newRow = {id:0, type:'8', content: {course:"", subject:"", year:""}};
    this.more_data['8'].push(newRow);
  }

  addNewWork(){
    var newRow = {id:0, type:'9', content: {title:"",type:"",company:"",location:"",start:"",end:"",salary:""}};
    this.more_data['9'].push(newRow);
  }


  addNewLang(){
    var newRow = {id:0, type:'10', content: {language:"", proficiency:""}};
    this.more_data['10'].push(newRow);
  }

  updateField(type, index, val){
    this.more_data[type][index].content = val;
    
  }

  updateJsonField(type, index, field, val){
    this.more_data[type][index].content[field] = val;
  }

  removeRow(type, i, id){
    this.more_data[type].splice(i, 1);

    if(id !=0) {
      let data = {id:id};

      this.dataService.postDeleteSdMore(data).subscribe( (res:any) => {
        
      });

    }

  }

}
