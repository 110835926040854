<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Fee Structure</h3>
            </div>

            <div class="col-md-6 text-right">
                
            </div>
        </div>

        <div class="page-body" *ngFor="let course of data.courses">

            <div class="row">
              <div class="col-md-6">
                <h4>
                  {{course.name}}
                </h4>
              </div>
              <div class="col-md-6 text-right">
                
              </div>
            </div>

            
              

            <hr>

            
            <h6 class="mb-4">Batches</h6>

            <ng-container *ngIf="course.batches.length > 0">
                <div class="row mb-4" *ngFor="let batch of course.batches">
                    <div class="col-5">
                        <p>
                            <strong>Batch {{batch.start_year}}</strong> &nbsp; 
                            <button class="btn btn-action btn-sm" routerLink="/dashboard/fee-structure/{{batch.id}}">View Fee Structure</button>
                            
                            
                        </p>
        
                        
                    </div>

                    
                    
                </div>
            </ng-container>

            <ng-container *ngIf="course.batches.length == 0">
                <p class="text-muted">No batches added!</p>
            </ng-container>

        </div>
    </div>
</div>



<!-- modals -->

<!-- New course -->



  