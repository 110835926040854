<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>{{batch.course}} &nbsp; 
                    <small><i class="bi bi-pencil-square cursor-pointer" data-target="#modal-edit-batch" data-toggle="modal"></i></small> <br>
                    <small>Batch: {{batch.start_year}}</small>  &nbsp;
                    <small>Current Year: {{batch.current_semester}}</small>
                </h3>

                
                
            </div>

            <div class="col-md-6 text-right">
                <button type="button" class="btn btn-warning" data-target="#modal-manage-semesters" data-toggle="modal">Manage Years</button>
                &nbsp;
                <button type="button" class="btn btn-warning" routerLink="/dashboard/batch-attendance/{{id}}">General Attendance</button>
                &nbsp;
                <!--<button type="button" class="btn btn-action" data-target="#modal-new-student" data-toggle="modal">Add student</button>-->
                <button type="button" class="btn btn-action" routerLink="/dashboard/new-student/{{id}}">Add student</button>
            </div>
        </div>

        <div class="page-body">
            <div class="row">
                <div class="col-md-6">
                    <h4>
                        <ng-container *ngIf="batch.class_teachers.length == 1">Class Teacher</ng-container>
                        <ng-container *ngIf="batch.class_teachers.length != 1">Class Teachers</ng-container>
                    </h4>
                </div>

                <div class="col-md-6 text-right">
                    <button class="btn btn-sm btn-action" data-target="#modal-assign-faculty" data-toggle="modal" *ngIf="role_id == 1 || role_id == 2">Assign class teacher</button>
                </div>
            </div>

            <p *ngFor="let teacher of batch.class_teachers">
                <i class="bi bi-person-fill"></i> {{teacher.name}} 
                <i class="btn-remove bi bi-x-circle-fill" (click)="removeClassTeacher(teacher)" *ngIf="role_id == 1 || role_id == 2"></i>
            </p>
        </div>

        <div class="page-body">

            <div class="row">
                <div class="col-8">
                    <h4>Students ({{students.count}})</h4>        
                </div>

                <div class="col-4 position-relative">
                    <form #fsearch="ngForm" (ngSubmit)="searchData(fsearch.value)" id="formSearch" ngNativeValidate>
                        <input type="text" class="form-control" placeholder="Search by student name or ID" [(ngModel)]="search" name="search"/>
                        <span *ngIf="search != ''" class="btn-clear-search" (click)="clearSearch()"> &times; </span>
                    </form>
                </div>
            </div>

            

            <hr>

            <table class="table table-borderless table-striped">
                <thead>
                    <td>ID</td>
                    <td>Name</td>
                    <td>Phone</td>
                    
                    <td></td>
                </thead>

                <tbody>
                    <ng-container *ngIf="students.list.length > 0; else noData">
                        <tr *ngFor="let student of students.list">
                            <td>{{student.student_id}}</td>
                            <td>{{student.name}}</td>
                            <td>{{student.phone}}</td>
                            <td>
                                <button class="btn btn-sm btn-action" routerLink="/dashboard/student/{{student.id}}">View profile</button>
                            </td>
                        </tr>
                    </ng-container>
                    
                    
                    <ng-template #noData>
                        <tr>
                           <td colspan="6" class="text-center">
                            No students added!
                           </td> 
                        </tr>
                    </ng-template>
                </tbody>

            </table>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6">
                  <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                </div>
                <div class="col-md-6 text-right">
                  <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="students.count > offset+limit" (click)="paginate(2)" />
                </div>
              </div>

        </div>

    </div>
</div>


<!-- New Student -->
<div class="modal fade" id="modal-new-student" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add New Student</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fstudent="ngForm" (ngSubmit)="newStudent(fstudent.value)" id="formNewStudent" ngNativeValidate>
                <div class="form-group">
                    <label for="">Name</label>
                    <input class="form-control" type="text" ngModel name="name" required autocomplete="off">
                </div>

                <div class="form-group">
                    <label for="">College ID</label>
                    <input class="form-control" type="text" ngModel name="student_id" autocomplete="off">
                </div>

                <div class="form-group">
                    <label for="">University ID</label>
                    <input class="form-control" type="text" ngModel name="university_id" autocomplete="off">
                </div>

                <div class="form-group">
                    <label for="">Phone</label>
                    <input class="form-control" type="text" ngModel name="phone" autocomplete="off">
                </div>

                <div class="form-group">
                    <label for="">DOB</label>
                    <input class="form-control" type="date" ngModel name="dob">
                </div>

                <div class="form-group">
                  <label for="">Category</label>
                  <select ngModel name="category_id" class="form-control">
                    <option *ngFor="let c of feeCategories" value="{{c.id}}">{{c.name}}</option>
                  </select>
                </div>  
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNewStudent" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>


<!-- assign class teacher-->

<div class="modal fade" id="modal-assign-faculty" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Assign Class Teacher</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #ffaculty="ngForm" (ngSubmit)="assignFaculty(ffaculty.value)" id="formFaculty" ngNativeValidate>
                <div class="form-group">
                    <label for="">Select Faculty</label>
                    <select ngModel name="faculty_id" class="form-control" required>
                        <option *ngFor="let faculty of faculties" value="{{faculty.id}}">{{faculty.name}}</option>
                    </select>
                </div>

                
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formFaculty" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<!-- edit batch-->
<div class="modal fade" id="modal-edit-batch" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit batch</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fbatch="ngForm" (ngSubmit)="editBatch(fbatch.value)" id="formEditBatch" ngNativeValidate>
                <div class="form-group">
                    <label for="">Starting Year</label>
                    <input class="form-control" type="text" [(ngModel)]="batch.start_year" name="start_year" required>
                </div>

                <div class="form-group">
                    <label for="">Ending Year</label>
                    <input class="form-control" type="text" [(ngModel)]="batch.end_year" name="end_year" required>
                </div>

                <div class="form-group">
                    <label for="">Current Year</label>
                    <select class="form-control" [(ngModel)]="batch.semester_id" name="current_semester">
                        <option *ngFor="let sem of semesters" value="{{sem.id}}">{{sem.name}}</option>
                    </select>
                </div>
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formEditBatch" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>


<!-- Manage Semesters -->
<div class="modal fade" id="modal-manage-semesters" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Manage Years</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fsem="ngForm" (ngSubmit)="manageSemesters(fstudent.value)" id="formSems" ngNativeValidate>
                <ng-container *ngFor="let semester of semesters">
                    <h6>Year {{semester.name}}</h6>

                    <div class="row mb-4">
                        <div class="col-md-6">
                            <label for="">Start date</label>
                            <input type="date" class="form-control" [(ngModel)]="semester.period.start_date" name="start_date{{semester.id}}">
                        </div>

                        <div class="col-md-6">
                            <label for="">End date</label>
                            <input type="date" class="form-control" [(ngModel)]="semester.period.end_date" name="end_date{{semester.id}}">
                        </div>

                        
                    </div>

                    <hr>
                </ng-container>
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formSems" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>