<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>
                    IV: {{data.title}} 
                    
                </h3>
                <p>
                    <small>{{data.start_date}} - {{data.end_date}}</small> <br>
                    <small>{{data.description}}</small> &nbsp;
                    
                </p>

                <p>
                    Batch: {{data.batch.course_name}} ({{data.batch.start_year}}) Year: {{data.semester}}
                </p>
            </div>
        </div>

        <div class="page-body">

            <ul class="nav nav-tabs mb-4">
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(selectedPage == 1) ? 'active' : ''" href="javascript:void(0);" (click)="selectPage(1)">Notes</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="(selectedPage == 2) ? 'active' : ''" href="javascript:void(0);" (click)="selectPage(2)">Students</a>
                </li>
                
            </ul>

            <!-- notes -->
            <ng-container *ngIf="selectedPage == 1">
                <h4 class="mb-4">Notes ({{notes.length}})</h4>

                <form #fnotes="ngForm" (ngSubmit)="newNote(fnotes.value)" id="formNewNote" ngNativeValidate>
                    <div class="row">
                        <div class="col-6 mb-4">
                            <textarea class="form-control" [(ngModel)]="newNoteText" name="note" placeholder="Enter note here" required></textarea>
                        </div>

                        <div class="col-6 pt-1">
                            <button *ngIf="!loading" form="formNewNote" class="btn btn-action">Post note</button>
                            <span class="spinner" *ngIf="loading">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                            </span>
                        </div>
                    </div>
                </form>

                <hr>

                <ng-container *ngIf="notes.length > 0">
                    <div class="row note-row mt-4" *ngFor="let note of notes">
                        <div class="col-6">
                            <small> 
                                <i class="bi bi-person-fill"></i> {{note.user.name}} &nbsp;&nbsp; <i class="bi bi-clock-fill"></i> {{note.timestamp}} 
                                | <i class="bi bi-trash3-fill btn-delete" (click)="deleteNote(note)"></i>
                            </small>
                            <p class="mb-0 mt-1 note-body">{{note.note}}</p>
                            
                        </div>
                    </div>
                </ng-container>
            </ng-container>



            <!-- students -->
            <ng-container *ngIf="selectedPage == 2">
                <h4 class="mb-4">Students ({{students.count}})</h4>

                <table class="table table-borderless table-striped">
                    <thead>
                        <td style="width:10%;">ID</td>
                        <td style="width:25%;">Name</td>
                        <td>Participated in IV?</td>
                        
                    </thead>
    
                    <tbody>
                        <ng-container *ngIf="students.list.length > 0; else noData">
                            <tr *ngFor="let student of students.list">
                                <td>
                                    <a href="javascript:void(0);" routerLink="/dashboard/student/{{student.id}}"> {{student.student_id}} </a>
                                </td>
                                <td>
                                    <a href="javascript:void(0);" routerLink="/dashboard/student/{{student.id}}"> {{student.name}} </a>
                                </td>
                                <td>
                                    <button class="btn btn-sm" 
                                    [ngClass]="(attendance[student.id] && attendance[student.id] == 1) ? 'btn-success' : 'btn-outline-dark' " 
                                    (click)="markAttendance(student.id, 1)"
                                >
                                    Yes
                               </button>
                               &nbsp;

                                <button class="btn btn-sm"
                                    [ngClass]="(attendance[student.id] && attendance[student.id] == 2) ? 'btn-danger' : 'btn-outline-dark' "
                                    (click)="markAttendance(student.id, 2)">
                                    No
                               </button>
                                </td>
                                
    
                                
                            </tr>
                        </ng-container>
                        
                        
                        <ng-template #noData>
                            <tr>
                               <td colspan="3" class="text-center">
                                No students added to the batch!
                               </td> 
                            </tr>
                        </ng-template>
                    </tbody>
    
                </table>
                <div class="row" style="margin-bottom: 30px;">
                    <div class="col-md-6">
                      <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                    </div>
                    <div class="col-md-6 text-right">
                      <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="students.count > offset+limit" (click)="paginate(2)" />
                    </div>
                </div>

            </ng-container>
            


        </div>
    </div>
</div>