<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Fee Structure -  <br> 
                    
                    <small> {{batch.course}} Batch: {{batch.start_year}}</small>  &nbsp;
                    
                </h3>
            </div>

            <div class="col-md-6 text-right">
                
            </div>
        </div>

        <div class="page-body">

            <div class="row">
                <div class="col-6 mb-4" *ngFor="let sem of data">
                    <h5 class="mb-4">Year: {{sem.semester.name}}</h5>
                    <hr>

                    <table class="table table-bordered">
                        <thead>
                            <th>Category</th>
                            <th>Seats</th>
                            <th>Amount</th>
                            <th>Total</th>
                            
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of sem.fee">
                                <td>
                                    {{row.category.name}}
                                </td>
                                <td>
                                    <input class="form-control" type="text" [(ngModel)]="row.seats" name="" id="" (blur)="updateFeeRow(row, sem)">
                                </td>
                                <td>
                                    <input class="form-control" type="text" [(ngModel)]="row.fee" name="" id="" (blur)="updateFeeRow(row, sem)">
                                </td>
                                <td>
                                    {{row.total}}
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            

        </div>
    </div>
</div>



<!-- modals -->

<!-- New course -->



  