import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-time-table',
  templateUrl: './time-table.component.html',
  styleUrls: ['./time-table.component.scss']
})
export class TimeTableComponent implements OnInit {
  
  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  batchId:any = 0;
  batch:any = {};
  data:any = {days:[], sessions:[], data:[{faculty:{id:0}}]}
  topics:any = [];
  loading:any = false;
  selectedSlot:any = {
    topic_id:0,
    subject:"",
    faculty:{
      id:0,
      name:""
    }
  }
  selectedDay:any = 0;
  selectedSession:any = 0;
  facultiesDrop:any = [];



  ngOnInit(): void {
    this.batchId = this.route.snapshot.paramMap.get('batchId');
    this.loadData();
    this.loadTopics();
    this.loadBatch();
  }

  loadData(){
    this.dataService.getTimeTable(this.batchId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
      
    } );
  }

  loadTopics(){
    this.dataService.getBatchTopics(this.batchId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.topics = data.topics;
      }
      
    } );
  }

  loadBatch(){
    this.dataService.getBatch(this.batchId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.batch = data;
      }
      
    } );
  }

  selectSlot(slot, day, session){
    this.selectedSlot = slot;
    this.selectedDay = day;
    this.selectedSession = session;
    console.log("slot", slot);
    //Set facultyDrop
    let topic = this.topics.find(item => item.id == slot.topic_id);
    this.facultiesDrop = topic.faculties;
    console.log(topic.faculties);
  }

  setDisplayText(val){
    let topic = this.topics.find(item => item.id == val);
    console.log(val, topic);
    this.selectedSlot.subject = topic.topic;
    this.facultiesDrop = topic.faculties;
    console.log(topic.faculties);
  }

  setSlotFacultyName(val){
    let faculty = this.facultiesDrop.find(item => item.id == val);
    if(faculty){
      this.selectedSlot.faculty.name = faculty.name;
    }
  }

  

  modifySlot(data){
    data.session = this.selectedSession;
    data.day = this.selectedDay;
    data.batch_id = this.batchId;
    
    this.dataService.postTTSlot(data).subscribe( (res:any) => {
      if(res.status == 0) {
        //@ts-ignore
        $("#modal-slot").modal("hide");
      } else {
        alert(res.msg);
      }

    });

  }

  slotInput(day, session){
    let text = prompt("Enter text", this.data.data[day][session]);
    if(text != null){
      this.data.data[day][session] = text;
      let data = {
        batch_id:this.batchId,
        day: day,
        session:session,
        text: text
      }
  
      this.dataService.postTTSlot(data).subscribe( (res:any) => {
        if(res.status == 0) {
          //
        } else {
          alert(res.msg);
        }
  
      });
    }
  }

}
