<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Courses ({{data.count}})</h3>
            </div>

            <div class="col-md-6 text-right">
                <button type="button" class="btn btn-action" data-target="#modal-new-course" data-toggle="modal">Add new course</button>
            </div>
        </div>

        <div class="page-body" *ngFor="let course of data.courses">

            <div class="row">
              <div class="col-md-6">
                <h4>
                  {{course.name}} &nbsp; 

                  | 
                  <i class="bi bi-pencil-square cursor-pointer" (click)="editCourse(course)"></i>
                  &nbsp;
                  <i class="bi bi-trash3-fill btn-delete" (click)="deleteCourse(course)"></i>
                </h4>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-action btn-sm" data-target="#modal-new-batch" data-toggle="modal" (click)="selectCourse(course)">Add Batch</button>
              &nbsp;
              <button class="btn btn-action btn-sm" data-target="#modal-manage-semesters" data-toggle="modal" (click)="selectCourse(course)">Manage Years</button>
              </div>
            </div>

            
              

            <hr>

            
            <h6 class="mb-4">Batches</h6>

            <ng-container *ngIf="course.batches.length > 0">
                <div class="row mb-4" *ngFor="let batch of course.batches">
                    <div class="col-5">
                        <p>
                            <strong>Batch {{batch.start_year}}</strong> &nbsp; 
                            <button class="btn btn-action btn-sm" routerLink="/dashboard/batch/{{batch.id}}">View Batch</button>
                            &nbsp;
                            <button class="btn btn-action btn-sm" routerLink="/dashboard/time-table/{{batch.id}}">Timetable</button>
                            &nbsp;
                            <button class="btn btn-action btn-sm" routerLink="/dashboard/syllabus/{{course.id}}">Syllabus</button>
                            
                        </p>
        
                        <p>
                            Current Year: {{batch.current_semester}} 
                        </p>

                        <p>
                            
                        </p>
                    </div>

                    
                    
                </div>
            </ng-container>

            <ng-container *ngIf="course.batches.length == 0">
                <p class="text-muted">No batches added!</p>
            </ng-container>

        </div>
    </div>
</div>



<!-- modals -->

<!-- New course -->
<div class="modal fade" id="modal-new-course" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add New Course</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fcourse="ngForm" (ngSubmit)="newCourse(fcourse.value)" id="formNewCourse" ngNativeValidate>
                <div class="form-group">
                    <label for="">Course Name</label>
                    <input class="form-control" type="text" ngModel name="course_name" required>
                </div>

                <div class="form-group">
                  <label for="">Course Duration (in years)</label>
                  <input class="form-control" type="number" ngModel name="course_duration" required>
                </div>

                <!--<div class="form-group">
                  <label for="">Semester Duration</label>
                  <select class="form-control" ngModel name="semester_duration" required>
                    <option value="6">6 Months</option>
                    <option value="12">12 Months</option>
                  </select>
                </div>  -->
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNewCourse" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<!-- new batch -->
<div class="modal fade" id="modal-new-batch" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add New Batch for {{selectedCourse.name}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fbatch="ngForm" (ngSubmit)="newBatch(fbatch.value)" id="formNewBatch" ngNativeValidate>
                <div class="form-group">
                    <label for="">Starting Year</label>
                    <input class="form-control" type="text" ngModel name="start_year" required>
                </div>

                <div class="form-group">
                    <label for="">Ending Year</label>
                    <input class="form-control" type="text" ngModel name="end_year" required>
                </div>

                <div class="form-group">
                  <label for="">Current Year</label>
                  <select class="form-control" ngModel name="current_semester" required>
                      <option *ngFor="let sem of selectedCourse.semesters" value="{{sem.id}}">{{sem.name}}</option>
                  </select>
                </div>
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNewBatch" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>


<!-- manage semesters -->
<div class="modal fade" id="modal-manage-semesters" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Manage Years for {{selectedCourse.name}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ng-container *ngIf="selectedCourse.semesters.length > 0">
          <div class="row mb-4">
            <div class="col-4"> <strong>Year</strong> </div>
            
          </div>
          <ng-container *ngFor="let sem of selectedCourse.semesters">
            <div class="row mb-1" *ngIf="!sem.editing">
              <div class="col-4">
                {{sem.name}}
              </div>
              
              <div class="col-4">
                <button class="btn btn-sm btn-outline-primary" (click)="enableEditSemester(sem)"><i class="bi bi-pencil-square"></i></button>
                &nbsp;
                <button class="btn btn-sm btn-outline-danger" (click)="deleteSemester(sem)"><i class="bi bi-x-square"></i></button>
              </div>
            </div>

            <div class="row mb-1" *ngIf="sem.editing">
              <div class="col-4">
                <input type="text" class="form-control" [(ngModel)]="sem.name" name="name" >
              </div>
              
              <div class="col-4">
                <button *ngIf="!sem.saving" class="btn btn-sm btn-outline-primary" (click)="editSemester(sem)">Save</button>
                <i *ngIf="sem.saving" class="fa-solid fa-spinner fa-spin"></i>
              </div>
            </div>
            <hr>
          </ng-container>
        </ng-container>    

        <div class="row mb-1">
          <div class="col-6">
            <input type="text" class="form-control" [(ngModel)]="newSemName" name="name" placeholder="Add new year" autocomplete="off">
          </div>
          <div class="col-6">
            <button *ngIf="!loading" class="btn btn-sm btn-outline-primary" (click)="newSemester()">Add</button>
            <i *ngIf="loading" class="fa-solid fa-spinner fa-spin"></i>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>


  