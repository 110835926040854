import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {status:0, courses:[], count:0}
  loading:any = false;
  selectedCourse:any = {semesters:[]};
  newSemName:any = "";

  ngOnInit(): void {
    this.loadData();
  }
  loadData(){
    this.dataService.getCourses().subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
    } );
  }


  newCourse(data){
    this.loading = true;
    this.dataService.postNewCourse(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //@ts-ignore
        $("#modal-new-course").modal("hide");
        this.loadData();

      } else {
        alert(res.msg);
      }

    });
  }

  selectCourse(course){
    this.selectedCourse = course;
  }

  newBatch(data){
    data.course_id = this.selectedCourse.id;
    this.loading = true;
    this.dataService.postNewBatch(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //@ts-ignore
        $("#modal-new-batch").modal("hide");
        this.loadData();

      } else {
        alert(res.msg);
      }

    });
  }

  enableEditSemester(sem){
    sem.editing = true;
  }

  editSemester(sem){
    sem.saving = true;
    this.dataService.postUpdateSemester(sem).subscribe( (res:any) => {
      sem.saving = false;
      if(res.status == 0) {
        sem.editing = false;
      } else {
        alert(res.msg);
      }

    });
  }

  newSemester(){
    let data = {name:this.newSemName, course_id:this.selectedCourse.id}
    this.loading = true;
    this.dataService.postNewSemester(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        this.selectedCourse.semesters.push(res.semester);
        this.newSemName = "";
      } else {
        alert(res.msg);
      }

    });
  }

  deleteSemester(sem){
    let cnf = confirm("Do you really want to delete this semester?");

    if(cnf){
      this.dataService.postDeleteSemester(sem).subscribe( (res:any) => {
      
        if(res.status == 0) {
          let semesters = this.selectedCourse.semesters.filter(semester => semester.id !== sem.id);
          this.selectedCourse.semesters = semesters;
        } else {
          alert(res.msg);
        }
  
      });
    }
    
  }

  editCourse(course){
    let input = prompt("Course Name", course.name);

    if(input != null && input.length > 0){
      let data = {
        course_id:course.id,
        name:input
      }

      this.dataService.postEditCourse(data).subscribe( (res:any) => {
      
        if(res.status == 0) {
          this.loadData();
        } else {
          alert(res.msg);
        }
  
      });
    }
  }

  deleteCourse(course){
    let cnf = confirm("Do you really want to delete the course " + course.name + "?");

    if(cnf){
      let data = {course_id:course.id};
      this.dataService.postDeleteCourse(data).subscribe( (res:any) => {
      
        if(res.status == 0) {
          this.loadData();
        } else {
          alert(res.msg);
        }
  
      });
    }

    
  }

}
