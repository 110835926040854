import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-fee-structure-main',
  templateUrl: './fee-structure-main.component.html',
  styleUrls: ['./fee-structure-main.component.scss']
})
export class FeeStructureMainComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {status:0, courses:[], count:0}
  loading:any = false;
  selectedCourse:any = {semesters:[]};
  newSemName:any = "";

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getCourses().subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
    } );
  }

}
