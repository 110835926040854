import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id:any = 0;
  batch:any = {class_teachers:[]};
  students:any = {list:[], count:0};
  limit:any = 30;
  offset:any = 0;
  search:any = "";
  loading:any = false;
  faculties:any = [];
  semesters:any = [];
  role_id:any = 0;
  feeCategories:any = [];

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadBatch();
    this.loadStudents();
    this.loadFaculties();
    this.loadSemesters();
    this.loadFeeCategories();

    this.role_id = localStorage.getItem("role_id");
    
  }

  loadBatch(){
    this.dataService.getBatch(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.batch = data;
      }
      
    } );
  }

  loadStudents(){
    this.dataService.getStudents(this.limit, this.offset, this.search, this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.students = data;
      }
    } );
  }

  loadFeeCategories(){
    this.dataService.getFeeCategories().subscribe( (data: any )=> {
      this.feeCategories = data;
      
    } );
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadStudents();

  }

  newStudent(data){
    data.batch_id = this.id;
    this.loading = true;
    this.dataService.postNewStudent(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //@ts-ignore
        $("#modal-new-student").modal("hide");
        this.loadStudents();

      } else {
        alert(res.msg);
      }

    });
  }

  searchData(data){
    this.search = data.search;
    this.offset = 0;
    this.loadStudents();
  }

  clearSearch(){
    this.search = "";
    this.loadStudents();
  }

  loadFaculties(){
    this.dataService.getFacultyList().subscribe( (data: any )=> {
      if(data.status == 0){
        this.faculties = data.list;
      }
      
    } );
  }

  assignFaculty(data){
    data.batch_id = this.id;
    
    this.dataService.postAssignClassTeacher(data).subscribe( (res:any) => {
      if(res.status == 0) {
        this.loadBatch();
        //@ts-ignore
        $("#modal-assign-faculty").modal("hide");
      } else {
        alert(res.msg);
      }

    });
  }

  removeClassTeacher(teacher){
    let cnf = confirm("Do you really want to remove "+ teacher.name + " from this batch?");

    if(cnf){
      let data = {
        id:teacher.id
      }

      this.dataService.postRemoveClassTeacher(data).subscribe( (res:any) => {
        if(res.status == 0) {
          this.loadBatch();
          
        } else {
          alert(res.msg);
        }
  
      });
    }
  }

  editBatch(data){
    data.batch_id = this.id;
    this.dataService.postEditBatch(data).subscribe( (res:any) => {
      if(res.status == 0) {
        this.loadBatch();
        //@ts-ignore
        $("#modal-edit-batch").modal("hide");
      } else {
        alert(res.msg);
      }

    });
  }

  loadSemesters(){
    this.dataService.getBatchSemesters(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.semesters = data.list;
      }
    } );
  }

  manageSemesters(input){
     let data = {
      batch_id:this.id,
      semesters:this.semesters
     }

    this.dataService.postEditSemesterPeriod(data).subscribe( (res:any) => {
      if(res.status == 0) {
        this.loadBatch();
        //@ts-ignore
        $("#modal-manage-semesters").modal("hide");
      } else {
        alert(res.msg);
      }

    });
  }

}
