<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-8">
                <h3> Attendance - {{slot.topic.name}} &nbsp; <br>
                    <small> {{slot.batch.course.name}} ( {{slot.batch.start_year}} - {{slot.batch.end_year}} ) </small>  &nbsp;
                    <small>Year: {{slot.batch.current_semester}}</small>
                </h3>

                
            </div>

            <div class="col-md-4 text-right">
                
                <span class="date-nav" (click)="backwardDate()">
                    &lt;
                </span>
                <!--<span class="selected-date" [ngClass]="(formattedDate(date) == formattedDate(today)) ? 'today' : ''">
                    {{formattedDate(date)}}
                </span>-->
                <input class="selected-date" [ngClass]="(formattedDate(date) == formattedDate(today)) ? 'today' : ''" type="date" value="{{formattedDate(date)}}" (change)="setCustomDate($event.target.value)"/>

                <span class="date-nav" (click)="forwardDate()" *ngIf="formattedDate(date) != formattedDate(today)">
                    &gt;
                </span>

                <span class="date-nav" *ngIf="formattedDate(date) == formattedDate(today)" style="visibility: hidden;">
                    
                </span>

            </div>
        </div>

        <div class="page-body">
            <h4>Students ({{students.count}})</h4>

            <hr>

            <table class="table table-borderless table-striped">
                <thead>
                    <td>ID</td>
                    <td>Name</td>
                    <td style="width: 60%;">Attendance</td>
                    
                </thead>

                <tbody>
                    <ng-container *ngIf="students.list.length > 0; else noData">
                        <tr *ngFor="let student of students.list">
                            <td>
                                <a href="javascript:void(0);" routerLink="/dashboard/student/{{student.id}}"> {{student.student_id}} </a>
                            </td>
                            <td>
                                <a href="javascript:void(0);" routerLink="/dashboard/student/{{student.id}}"> {{student.name}} </a>
                            </td>
                            
                            <td *ngIf="!isHoliday">
                                <button class="btn btn-sm" 
                                    [ngClass]="(!attendance[student.id] || attendance[student.id] == 1) ? 'btn-success' : 'btn-outline-dark' " 
                                    (click)="markAttendance(student.id, 1)"
                                >
                                    Present
                               </button>
                               &nbsp;

                                <button class="btn btn-sm"
                                    [ngClass]="(attendance[student.id] && attendance[student.id] == 2) ? 'btn-danger' : 'btn-outline-dark' "
                                    (click)="markAttendance(student.id, 2)">
                                    Absent
                               </button>

                            </td>

                            <td *ngIf="isHoliday">
                                <span class="badge badge-warning">HOLIDAY</span>
                            </td>

                            
                        </tr>
                    </ng-container>
                    
                    
                    <ng-template #noData>
                        <tr>
                           <td colspan="6" class="text-center">
                            No students added!
                           </td> 
                        </tr>
                    </ng-template>
                </tbody>

            </table>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6">
                  <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                </div>
                <div class="col-md-6 text-right">
                  <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="students.count > offset+limit" (click)="paginate(2)" />
                </div>
              </div>

        </div>

    </div>
</div>


