<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            
            <div class="col-10">
                <h3>Faculty Details</h3>
            </div>
        </div>
        <div class="page-body">

            <table class="table table-borderless table-striped" *ngIf="data.data.length > 0; else noUsers">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Form first accessed on</th>
                    <th scope="col">Form modified on</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data.data">
                    <td> {{item.user}}</td>
                    <td> {{item.designation}} </td>
                    <td>{{item.created}}</td>
                    <td>{{item.updated}}</td>
                    <td>
                      <!--<a href="#" [routerLink]="'/dashboard/staff-details-view/'+item.user_id"></a> &nbsp;&nbsp;&nbsp;-->
                      <a href="#" [routerLink]="'/dashboard/staff-details/'+item.user_id">View/Edit</a>
                    </td>
                    
                    
                  </tr>
                </tbody>
              </table>
              <ng-template #noUsers>
                <p class="text-center">No Data</p>
              </ng-template>

        </div>
    </div>
</div>