import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-examination',
  templateUrl: './examination.component.html',
  styleUrls: ['./examination.component.scss']
})
export class ExaminationComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  batchId:any = 0;
  subjectId:any = 0;
  
  data:any = {list:[], count:0};
  limit:any = 50;
  offset:any = 0;
  loading:any = false;
  search:any = "";
  exam:any = {types:[], settings:{},};
  selectedStudent:any = {scores:[], prev_scores:[]}

  ngOnInit(): void {
    this.batchId = this.route.snapshot.paramMap.get('batchId');
    this.subjectId = this.route.snapshot.paramMap.get('subjectId');
    this.loadExam();
    this.loadStudents();
    
  }

  loadExam(){
    this.dataService.getExam(this.batchId, this.subjectId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.exam = data;
      }
    } );
  }


  loadStudents(){
    this.dataService.getExamScores(this.batchId, this.subjectId, this.limit, this.offset, this.search).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
    } );
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadStudents();

  }

  saveExamScore(student, type, score){
    let data = {
      batch_id:this.batchId,
      subject_id:this.subjectId,
      student_id:student.id,
      type_id:type.id,
      score:score
    }

    this.dataService.postExamScore(data).subscribe( (res:any) => {
      if(res.status == 0) {
        
      } else {
        //alert(res.msg);
      }

    });
    
  }

  /*calculateResult(scores){
    for (const key in scores) {
      if (scores.hasOwnProperty(key)) {
        const scoreValue = scores[key];
        if (scoreValue === "") {
          return "-";
        }
        const type = this.exam.types.find((t) => t.id.toString() === key);
        if (type && type.min_score !== null) {
          const parsedScore = typeof scoreValue === "string" ? parseFloat(scoreValue) : scoreValue;
          if (!isNaN(parsedScore) && parsedScore >= type.min_score) {
            continue;
          } else {
            return "Failed";
          }
        }
      }
    }
    return "Passed";
    
  } */


  calculateResult(scores) {
    
    const results: string[] = [];
    let allScoresFilled = true;
    
    let totalMin = 0;
    let totalMax = 0;
    let totalScored = 0;
    this.exam.types.forEach(type => {
      if (this.exam.settings[type.code] === 1 ) {
        totalMax += type.max_score;
        totalScored += Number(scores[type.id]);
        if(type.min_score !== null){
          const score = scores[type.id.toString()];
          if (score !== "") {
            if (score >= type.min_score) {
              results.push("P");
            } else {
              results.push("F");
            }
          } else {
            allScoresFilled = false;
          }  
        }
        
      }
    });
  
    if (!allScoresFilled) {
      return "-";
    }

    
  
    if (results.length === 0) {
      return "P";
    }
  
    let finresult =  results.every(result => result === "P") ? "P" : "F";  

    totalMin = totalMax/2;
    if(finresult == "P"){
      if(totalScored >= totalMin){
        finresult = "P - " + totalMin;
      } else {
        finresult = "F";
      }
    }

    return finresult;
  }


  toggleSettings(field){
    if(this.exam.settings[field] == 0){
      this.exam.settings[field] = 1;
    } else {
      this.exam.settings[field] = 0;
    }

    let data = {
      subject_id:this.subjectId,
      settings: this.exam.settings
    } 

    this.dataService.postExamSettings(data).subscribe( (res:any) => {
      if(res.status == 0) {
        
      } else {
        //alert(res.msg);
      }

    });


  }

  submitSuppli(input){
    
    let data = {
      student_id: this.selectedStudent.id,
      batch_id: this.batchId,
      subject_id: this.subjectId,
      scores:input
    }

    this.loading = true;
    
    console.log(data)
    this.dataService.postExamSupplementaryScore(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //@ts-ignore
       $("#modal-supplementary-score").modal("hide");
      } else {
        //alert(res.msg);
      }

    });
  }

  selectStudent(student){
    console.log(student);
    this.selectedStudent = student;
  }

  editExamName(){
    let input  = prompt("Edit Exam Name", this.exam.exam_name);

    if(input){
      let data = {
        batch_id: this.batchId,
        name: input
      }

      this.dataService.postExamName(data).subscribe( (res:any) => {
        this.loading = false;
        if(res.status == 0) {
          this.loadExam();
         
        } else {
          //alert(res.msg);
        }
  
      });


    }

  }
  

}
