<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-8">
                <h3>{{titleVar}} Student</h3> <!-- include batch details -->
            </div>
            <div class="col-md-4 position-relative">
                
            </div>
        </div>

        <div class="page-body">

            <form #fstudent="ngForm" (ngSubmit)="submitStudent()" id="formStudent" ngNativeValidate>

                <div class="row">
                    <div class="col-md-4 mb-4">
                        <label>Name</label>
                        <input type="text" [(ngModel)]="data.name" name="name" class="form-control" required>
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>Student ID</label>
                        <input type="text" [(ngModel)]="data.student_id" name="student_id" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>University ID</label>
                        <input type="text" [(ngModel)]="data.university_id" name="university_id" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>Category</label>
                        <select [(ngModel)]="data.category_id" name="category_id" class="form-control">
                            <option *ngFor="let c of feeCategories" value="{{c.id}}">{{c.name}}</option>
                        </select>
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>Sex</label>
                        <p>
                            <button type="button" class="form-option btn btn-sm mr-2" [ngClass]="data.sex === 'Male' ? 'btn-primary' : 'btn-outline-primary'" (click)="setField('sex', 'Male')">Male</button>
                            <button type="button" class="form-option btn btn-sm" [ngClass]="data.sex === 'Female' ? 'btn-primary' : 'btn-outline-primary'" (click)="setField('sex', 'Female')">Female</button>
                        </p>
                        
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>Date of Birth</label>
                        <input type="date" [(ngModel)]="data.dob" name="dob" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>Permanent Address</label>
                        <input type="text" [(ngModel)]="data.address" name="address" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>Pincode</label>
                        <input type="text" [(ngModel)]="data.pincode" name="pincode" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>Country</label>
                        <input type="text" [(ngModel)]="data.country" name="country" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>panchayath/municipality</label>
                        <input type="text" [(ngModel)]="data.local_body" name="local_body" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>urban/rural</label>
                        <p>
                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.urban_rural == 'Urban' ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('urban_rural', 'Urban')">
                                Urban
                            </button>
                            <button type="button"
                            class="form-option btn btn-sm" 
                            [ngClass]="data.urban_rural == 'Rural' ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('urban_rural', 'Rural')">
                                Rural
                            </button>
                        </p>
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>community</label>
                        
                        <p>
                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.community == 'GEN' ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('community', 'GEN')">
                                GEN
                            </button>
                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.community == 'SC' ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('community', 'SC')">
                                SC
                            </button>
                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.community == 'ST' ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('community', 'ST')">
                                ST
                            </button>
                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.community == 'OBC' ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('community', 'OBC')">
                                OBC
                            </button>
                        </p>
                        
                        
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>religion</label>
                        <input type="text" [(ngModel)]="data.religion" name="religion" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>caste</label>
                        <input type="text" [(ngModel)]="data.caste" name="caste" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>blood group</label>
                        <input type="text" [(ngModel)]="data.blood_group" name="blood_group" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>aadhaar no. (student)</label>
                        <input type="text" [(ngModel)]="data.aadhaar_no" name="aadhaar_no" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>mobile(student)</label>
                        <input type="text" [(ngModel)]="data.phone" name="phone" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>email id</label>
                        <input type="text" [(ngModel)]="data.email" name="email" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>mother's name</label>
                        <input type="text" [(ngModel)]="data.mother_name" name="mother_name" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>mother's occupation</label>
                        <input type="text" [(ngModel)]="data.mother_occupation" name="mother_occupation" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>mother's phone/email</label>
                        <input type="text" [(ngModel)]="data.mother_phone" name="mother_phone" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>father's name</label>
                        <input type="text" [(ngModel)]="data.father_name" name="father_name" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>father's occupation</label>
                        <input type="text" [(ngModel)]="data.father_occupation" name="father_occupation" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>father's phone/email</label>
                        <input type="text" [(ngModel)]="data.father_phone" name="father_phone" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>person with disability?</label>

                        <p>
                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.has_disability == 1 ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('has_disability', 1)">
                                Yes
                            </button>

                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.has_disability == 0 ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('has_disability', 0)">
                                No
                            </button>
                        </p>
                        
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>write disability and assistive device student uses</label>
                        <input type="text" [(ngModel)]="data.disability_description" name="disability_description" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>Hostel facility required?</label>

                        <p>
                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.require_hostel == 1 ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('require_hostel', 1)">
                                Yes
                            </button>

                            <button type="button"
                            class="form-option btn btn-sm mr-2" 
                            [ngClass]="data.require_hostel == 0 ? 'btn-primary' : 'btn-outline-primary'" 
                            (click)="setField('require_hostel', 0)">
                                No
                            </button>
                        </p>
                        
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>local guardian name</label>
                        <input type="text" [(ngModel)]="data.guardian_name" name="guardian_name" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>local guarian phone</label>
                        <input type="text" [(ngModel)]="data.guardian_phone" name="guardian_phone" class="form-control">
                    </div>

                    <div class="col-md-4 mb-4">
                        <label>local guardian's relationship with student</label>
                        <input type="text" [(ngModel)]="data.guardian_rel" name="guardian_rel" class="form-control">
                    </div>

                </div>

            </form>

            <button *ngIf="!loading" class="btn btn-action" form="formStudent">Save Student</button>
            <span class="spinner" *ngIf="loading">
                <i class="fa-solid fa-spinner fa-spin"></i>
              </span>

        </div>

    </div>
</div>