import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-fee-payments',
  templateUrl: './fee-payments.component.html',
  styleUrls: ['./fee-payments.component.scss']
})
export class FeePaymentsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {list:[], count:0}
  limit:any = 30;
  offset:any = 0;
  date:any = "";
  

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getFeePaymentLog(this.limit, this.offset, this.date).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
    } );
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadData();

  }

}
