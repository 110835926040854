<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Industral Visits ({{data.count}})</h3>
            </div>

            <div class="col-md-6 text-right">
                <button type="button" class="btn btn-action" data-target="#modal-new-iv" data-toggle="modal">Add new IV</button>
            </div>
        </div>

        <div class="page-body">
            <table class="table table-borderless table-striped">
                <thead>
                    
                    <td>Title</td>
                    <td>Description</td>
                    <td>Start Date</td>
                    <td>End Date</td>
                    <td>Batch</td>
                    <td>Year</td>
                    <td></td>
                </thead>

                <tbody>
                    <ng-container *ngIf="data.list.length > 0; else noData">
                        <tr *ngFor="let row of data.list">
                            
                            <td>{{row.title}}</td>
                            <td>{{row.description}}</td>
                            <td>{{row.start_date}}</td>
                            <td>{{row.end_date}}</td>
                            <td>
                                {{row.batch.course_name}} ({{row.batch.start_year}})
                                
                            </td>
                            <td> {{row.semester}} </td>
                            <td>
                                <button class="btn btn-sm btn-action" routerLink="/dashboard/industrial-visit/{{row.id}}">View details</button>
                            </td>
                        </tr>
                    </ng-container>
                    
                    
                    <ng-template #noData>
                        <tr>
                            <td colspan="7" class="text-center">
                                No data to show!    
                            </td>
                            
                        </tr>
                    </ng-template>
                </tbody>

            </table>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6">
                  <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                </div>
                <div class="col-md-6 text-right">
                  <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="data.count > offset+limit" (click)="paginate(2)" />
                </div>
            </div>
        </div>
    </div>
</div>



<!-- modals -->

<div class="modal fade" id="modal-new-iv" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add New IV</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fiv="ngForm" (ngSubmit)="newIV(fiv.value)" id="formNewIV" ngNativeValidate>
                <div class="form-group">
                    <label for="">Title</label>
                    <input class="form-control" type="text" ngModel name="title" required>
                </div>

                <div class="form-group">
                    <label for="">Description</label>
                    <input class="form-control" type="text" ngModel name="description">
                </div>

                

                <div class="form-group">
                    <label for="">Batch</label>
                    <select class="form-control" ngModel name="batch_id" id="" (change)="setSemester($event.target.value)" required>
                        <option *ngFor="let batch of batches" value="{{batch.id}}">{{batch.course_name}} ({{batch.start_year}}) </option>
                    </select>
                </div>

                <div class="form-group" style="display: none;">
                    <label for="">Year</label>
                    <input class="form-control" type="text" [(ngModel)]="ivSemester" name="semester" required>
                </div>

                <div class="form-group">
                    <label for="">Start Date</label>
                    <input class="form-control" type="date" ngModel name="start_date" required>
                </div>

                <div class="form-group">
                    <label for="">Start Date</label>
                    <input class="form-control" type="date" ngModel name="end_date" required>
                </div>
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNewIV" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>