import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {
  data:any = {status:0, list:[], count:0}
  limit:any = 30;
  offset:any = 0;
  search:any = "";
  batchId:any = 0;
  
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getStudents(this.limit, this.offset, this.search, this.batchId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
    } );
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadData();

  }

  searchData(data){
    this.search = data.search;
    this.offset = 0;
    this.loadData();
  }

  clearSearch(){
    this.search = "";
    this.loadData();
  }

}
