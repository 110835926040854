<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>
                    {{exam.name}}

                    
                </h3>

                <h5 class="mb-4">
                    {{data.batchName}}
                </h5>

                <h5 class="mb-4">
                    Exam: {{exam.exam_name}} &nbsp; <i class="fa-regular fa-pen-to-square cursor-pointer" (click)="editExamName()"></i>
                </h5>



                <div class="row">
                    <div class="col-2">
                        <ng-container *ngIf="exam.settings.has_viva == 0"><i class="cursor-pointer fa-regular fa-square" (click)="toggleSettings('has_viva')"></i></ng-container>
                        <ng-container *ngIf="exam.settings.has_viva == 1"><i class="cursor-pointer  fa-regular fa-square-check" (click)="toggleSettings('has_viva')"></i></ng-container>
                        
                        <span class="cursor-pointer" (click)="toggleSettings('has_viva')">
                            &nbsp; Viva
                        </span>
                    </div>

                    <div class="col-2">
                        <ng-container *ngIf="exam.settings.has_practical == 0"><i class="cursor-pointer fa-regular fa-square" (click)="toggleSettings('has_practical')"></i></ng-container>
                        <ng-container *ngIf="exam.settings.has_practical == 1"><i class="cursor-pointer  fa-regular fa-square-check" (click)="toggleSettings('has_practical')"></i></ng-container>
                        
                        <span class="cursor-pointer" (click)="toggleSettings('has_practical')">
                            &nbsp; Practical
                        </span>
                    </div>

                    <div class="col-3">
                        <ng-container *ngIf="exam.settings.has_practical_viva == 0"><i class="cursor-pointer fa-regular fa-square" (click)="toggleSettings('has_practical_viva')"></i></ng-container>
                        <ng-container *ngIf="exam.settings.has_practical_viva == 1"><i class="cursor-pointer  fa-regular fa-square-check" (click)="toggleSettings('has_practical_viva')"></i></ng-container>
                        
                        <span class="cursor-pointer" (click)="toggleSettings('has_practical_viva')">
                            &nbsp; Practical Viva
                        </span>
                    </div>
                </div>

                <p>
                    <!--Batch: {{exam.batch.course_name}} ({{exam.batch.start_year}} - {{exam.batch.end_year}}) Year: {{exam.semester.name}}-->
                </p>

                
            </div>

            <div class="col-md-6 text-right">
                
            </div>
        </div>

        <div class="page-body">
            
            <table class="table table-borderless table-striped">
                <thead>
                    <td>Student</td>
                    
                    <ng-container *ngFor="let type of exam.types">
                        <td *ngIf="exam.settings[type.code] == 1">
                            {{type.name}}
                            <br>
                            <small>Max. Score - {{type.max_score}} <br> Min. Score - {{type.min_score}}</small>
                        </td>
                    </ng-container>
                    
                    <td>
                        Subject Total
                    </td>
                    <td>
                        Result
                    </td>
                </thead>

                <tbody>
                    <ng-container *ngIf="data.list.length > 0; else noData">
                        <tr *ngFor="let student of data.list">
                            <td> {{student.name}} <br> <small>{{student.student_id}}</small> </td>
                            
                            <ng-container *ngFor="let type of exam.types">
                                <td *ngIf="exam.settings[type.code] == 1">
                                    <input type="text" class="form-control" [(ngModel)]="student.scores[type.id]" (input)="saveExamScore(student, type, $event.target.value)" >
                                </td>
                            </ng-container>
                            
                            <td>
                                <small>Theory Total: {{student.theory_total}}</small>
                                <br>
                                <small *ngIf="exam.settings.has_practical == 1">Practical Total: {{student.pr_total}}</small>
                            </td>
                            <td>
                                
                                {{calculateResult(student.scores)}}
                                <p class="mb-0" *ngIf="calculateResult(student.scores) == 'F'">
                                    <br>
                                    <a class="mr-2" href="#modal-supplementary-score" data-toggle="modal" (click)="selectStudent(student)">Supplementary score</a>
                                </p>

                                <p *ngIf="student.prev_scores.length > 0">
                                    <a href="#modal-prev-score" data-toggle="modal" (click)="selectStudent(student)">View Previous Scores</a>
                                </p>

                            </td>
                            
                        </tr>
                    </ng-container>
                    
                    
                    <ng-template #noData>
                        <tr colspan="6" class="text-center">
                            No students added!
                        </tr>
                    </ng-template>
                </tbody>

            </table>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6">
                  <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                </div>
                <div class="col-md-6 text-right">
                  <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="data.count > offset+limit" (click)="paginate(2)" />
                </div>
            </div>

        </div>

    </div>
</div>

<!--modals-->
<div class="modal fade" id="modal-supplementary-score" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Supplementary Score</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fsuppli="ngForm" (ngSubmit)="submitSuppli(fsuppli.value)" id="formSuppli" ngNativeValidate>
                <div class="row">
                    
                    <table class="table table-bordered">
                        <thead>
                            <ng-container *ngFor="let type of exam.types">
                                <td *ngIf="exam.settings[type.code] == 1">
                                    {{type.name}}
                                    <br>
                                    <small>Max. Score - {{type.max_score}} <br> Min. Score - {{type.min_score}}</small>
                                </td>
                            </ng-container>
                        </thead>

                        <tbody>
                            <tr>
                                <ng-container *ngFor="let type of exam.types">
                                    <td *ngIf="exam.settings[type.code] == 1">
                                        <input type="text" class="form-control" name="{{type.id}}" [(ngModel)]="selectedStudent.scores[type.id]">
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formSuppli" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="modal-prev-score" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Previous Scores</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fsuppli="ngForm" (ngSubmit)="submitSuppli(fsuppli.value)" id="formSuppli" ngNativeValidate>
                <div class="row">
                    
                    <table class="table table-bordered">
                        <thead>
                            <td>Date</td>
                            <ng-container *ngFor="let type of exam.types">
                                <td *ngIf="exam.settings[type.code] == 1">
                                    {{type.name}}
                                    
                                </td>
                            </ng-container>
                        </thead>

                        <tbody>
                            <tr *ngFor="let prev of this.selectedStudent.prev_scores">
                                <td>{{prev.date}}</td>
                                <ng-container *ngFor="let type of exam.types">
                                    <td *ngIf="exam.settings[type.code] == 1">
                                        {{prev.data[type.id]}} 
                                    </td>
                                </ng-container>
                               
                                
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                
            </form>

        </div>
        
      </div>
    </div>
</div>

<!-- assign subject-->
<!--<div class="modal fade" id="modal-edit-date" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Assign subject to exam</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fdate="ngForm" (ngSubmit)="editDate(fdate.value)" id="formDate" ngNativeValidate>
                <div class="row">
                    
    
                    

                    <div class="col-md-12 mb-4">
                        <label for="">Date</label>
                        <input type="date" ngModel name="date" class="form-control">
                    </div>

                    
                </div>
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formDate" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>-->

