<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Faculties ({{data.count}})</h3>
            </div>

            <div class="col-md-6 text-right">
                <button type="button" class="btn btn-action" data-target="#modal-new-faculty" data-toggle="modal">Add new faculty</button>
            </div>
        </div>


        <div class="page-body">
            <table class="table table-borderless table-striped">
                <thead>
                    
                    <td>Name</td>
                    <td>Username</td>
                    <td>Role</td>
                    <td></td>
                </thead>

                <tbody>
                    <ng-container *ngIf="data.list.length > 0; else noData">
                        <tr *ngFor="let row of data.list">
                            
                            <td>{{row.name}}</td>
                            <td>{{row.user_name}}</td>
                            <td>{{row.role.name}}</td>
                            <td>
                                <button class="btn btn-sm btn-action" data-target="#modal-edit-faculty" data-toggle="modal" (click)="selectFaculty(row)">
                                    Edit
                                </button>
                                &nbsp;
                                <button class="btn btn-sm btn-action" (click)="deleteUser(row)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    
                    
                    <ng-template #noData>
                        <tr colspan="4" class="text-center">
                            No data to show!
                        </tr>
                    </ng-template>
                </tbody>

            </table>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6">
                  <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                </div>
                <div class="col-md-6 text-right">
                  <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="data.count > offset+limit" (click)="paginate(2)" />
                </div>
            </div>
        </div>

    </div>
</div>


<!--modals -->

<div class="modal fade" id="modal-new-faculty" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add new faculty</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #fnew="ngForm" (ngSubmit)="submitFaculty(fnew.value)" id="formNew" ngNativeValidate>
              
            <div class="form-group">
                <label class="control-label col-md-12">Name</label>
                <div class="col-md-12">
                    <input type="text" ngModel name="name" class="form-control" required/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Username/Email</label>
                <div class="col-md-12">
                    <input type="text" ngModel name="user_name" class="form-control" required/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Role</label>
                <div class="col-md-12">
                    <select class="form-control" ngModel name="role" required>
                        <option *ngFor="let role of roles" value="{{role.id}}"> {{role.group_name}}  </option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Password</label>
                <div class="col-md-12">
                    <input type="text" ngModel name="password" class="form-control" minlength="6" required/>
                </div>
            </div>

            
            

            

          </form>
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNew" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<!-- edit faculty -->

<div class="modal fade" id="modal-edit-faculty" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Faculty</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #fedit="ngForm" (ngSubmit)="editUser(fedit.value)" id="formEdit" ngNativeValidate>
              
            <div class="form-group">
                <label class="control-label col-md-12">Name</label>
                <div class="col-md-12">
                    <input type="text" [(ngModel)]="selectedUser.name" name="name" class="form-control" required/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Username/Email</label>
                <div class="col-md-12">
                    <input type="text" [(ngModel)]="selectedUser.user_name" name="user_name" class="form-control" required/>
                </div>
            </div>

            <div class="form-group" *ngIf="data.is_admin">
                <label class="control-label col-md-12">Role</label>
                <div class="col-md-12">
                    <select class="form-control" [(ngModel)]="selectedUser.role_id" name="role" required>
                        <option *ngFor="let role of data.roles" value="{{role.id}}"> {{role.name}}  </option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Password (only if you need to change the password)</label>
                <div class="col-md-12">
                    <input type="text" ngModel name="password" class="form-control" minlength="6"/>
                </div>
            </div>

            
            

            

          </form>
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formEdit" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>