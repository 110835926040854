<div class="page">
    <div class="container-fluid">

        <div class="row student-header">
            <div class="col-2 text-center">
                <p>
                    <img *ngIf="photo_url == 0" src="assets/img/user-placeholder.png" alt="">
                    <img *ngIf="photo_url != 0" [src]="photo_url" alt="">
                </p>
                <small>
                    <a href="#modal-upload-photo" data-toggle="modal">Upload</a>
                </small>
            </div>
            <div class="col-9">
                <h3 class="pt-1">{{data.name}} &nbsp; 
                    <!--<small><i class="bi bi-pencil-square cursor-pointer" data-target="#modal-edit" data-toggle="modal"></i></small> -->
                    <small><i class="bi bi-pencil-square cursor-pointer" routerLink="/dashboard/edit-student/{{id}}"></i></small> 
                </h3>
                <h6 *ngIf="data.batch.id != 0">Course: {{data.batch.course_name}} ({{data.batch.start_year}})  | Year {{data.batch.current_semester}}</h6>

                <h6>
                    College ID: {{data.student_id}} &nbsp; University ID: {{data.university_id}}
                </h6>

                
                <h6>Category: {{data.category.name}}</h6>
            </div>

            

        </div>

        

        <div class="student-body">

            <ul class="nav nav-tabs mb-4">
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(selectedView == 'details') ? 'active' : ''" href="javascript:void(0);" (click)="selectView('details')">Student Details</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(selectedView == 'score') ? 'active' : ''" href="javascript:void(0);" (click)="selectView('score')">Exam Scores</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(selectedView == 'fee') ? 'active' : ''" href="javascript:void(0);" (click)="selectView('fee')">Fee Payments</a>
                </li>
                
            </ul>

            <ng-container *ngIf="selectedView == 'details'">
                <h4>Student Details</h4>
                <div class="row pt-4">
                    <div class="col-2 mb-4">
                        <label>sex</label>
                        <p>{{profile.sex}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>date of birth</label>
                        <p>{{ profile.dob | date: 'dd/MM/yyyy' }}</p>
                    </div>
        
                    <div class="col-2 mb-4">
                        <label>permanent address</label>
                        <p>{{profile.address}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>pincode</label>
                        <p>{{profile.pincode}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>country</label>
                        <p>{{profile.country}}</p>
                    </div>
        
                    <div class="col-2 mb-4">
                        <label>panchayath/municipality</label>
                        <p>{{profile.local_body}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>urban/rural</label>
                        <p>{{profile.urban_rural}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>community</label>
                        <p>{{profile.community}}</p>
                    </div>
                    
                    <div class="col-2 mb-4">
                        <label>religion</label>
                        <p>{{profile.religion}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>caste</label>
                        <p>{{profile.caste}}</p>
                    </div>
                    
                    <div class="col-2 mb-4">
                        <label>blood group</label>
                        <p>{{profile.blood_group}}</p>
                    </div>
                    
                    <div class="col-2 mb-4">
                        <label>aadhaar no.</label>
                        <p>{{profile.aadhaar_no}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>mobile (student)</label>
                        <p>{{profile.phone}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>email id</label>
                        <p>{{profile.email}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>mother's name</label>
                        <p>{{profile.mother_name}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>mother's occupation</label>
                        <p>{{profile.mother_occupation}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>mother's phone/email</label>
                        <p>{{profile.mother_phone}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>father's name</label>
                        <p>{{profile.father_name}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>father's occupation</label>
                        <p>{{profile.father_occupation}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>father's phone/email</label>
                        <p>{{profile.father_phone}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>person with disability</label>
                        <p>
                            <span *ngIf="profile.has_disability == 1">Yes</span>
                            <span *ngIf="profile.has_disability == 0">No</span>
                        </p>
                    </div>

                    <div class="col-3 mb-4">
                        <label>write disability and assistive device student uses</label>
                        <p>{{profile.disability_description}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>hostel facility required?</label>
                        <p>
                            <span *ngIf="profile.require_hostel == 1">Yes</span>
                            <span *ngIf="profile.require_hostel == 0">No</span>
                        </p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>local guardian's name</label>
                        <p>{{profile.guardian_name}}</p>
                    </div>

                    <div class="col-2 mb-4">
                        <label>local guardian's phone</label>
                        <p>{{profile.guardian_phone}}</p>
                    </div>

                    <div class="col-3 mb-4">
                        <label>local guardian's relationship with student</label>
                        <p>{{profile.guardian_rel}}</p>
                    </div>
                    
        
                </div>
            </ng-container>

            
            <ng-container *ngIf="selectedView == 'score'">
                <h4 class="mb-4">Examination Scores</h4>

                <ul class="nav nav-pills mb-4">
                    <li class="nav-item mr-3" *ngFor="let sem of semesters">
                      <a class="nav-link" [ngClass]="(sem.id == examData.sem_id) ? 'active' : ''" href="javascript:void(0);" (click)="selectSem(sem)">Year: {{sem.name}}</a>
                    </li>
                    
                  </ul>

                <!--Year: {{examData.sem_name}} -->


                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <td rowspan="2">Sl No.</td>
                            <td colspan="2">Subject</td>
                            <td colspan="3">Theory</td>
                            <td colspan="3">Practical</td>
                            <td colspan="3">Total</td>
                            <td rowspan="2">Result</td>
                        </tr>
                        <tr>
                            <td colspan="2">Paper</td>
                            <td>Max</td>
                            <td>Min</td>
                            <td>Awarded</td>
                            <td>Max</td>
                            <td>Min</td>
                            <td>Awarded</td>
                            <td>Max</td>
                            <td>Min</td>
                            <td>Awarded</td>
                        </tr>
                    </thead>

                    <tbody>
                        <ng-container *ngFor="let row of examData.list; let sl = index">
                            <tr>
                                <td rowspan="5">{{sl+1}}</td>
                                <td rowspan="5" style="width: 50%;">{{row.subject_name}}</td>
                                
                                
                            </tr>
                            <tr>
                                <td>IA</td>
                                <td>{{examData.types[4].max_score}}</td>
                                <td>{{examData.types[4].min_score}}</td>
                                <td>{{row.scores[4].score}}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td rowspan="4">{{row.net_max}}</td>
                                <td rowspan="4">{{row.net_min}}</td>
                                <td rowspan="4">{{row.net_total}}</td>
                                <td rowspan="4">
                                    {{row.result}}
                                    
                                </td>
                            </tr>
                            <tr>
                                <td>University</td>
                                <td>{{examData.types[1].max_score}}</td>
                                <td>{{examData.types[1].min_score}}</td>
                                <td>{{row.scores[1].score}}</td>
                                <td *ngIf="row.settings.has_practical == 1">{{examData.types[5].max_score}}</td>
                                <td *ngIf="row.settings.has_practical == 0">-</td>
                                <td *ngIf="row.settings.has_practical == 1">{{examData.types[5].min_score}}</td>
                                <td *ngIf="row.settings.has_practical == 0">-</td>
                                <td *ngIf="row.settings.has_practical == 1">{{row.scores[5].score}}</td>
                                <td *ngIf="row.settings.has_practical == 0">-</td>
                                
                                
                                
                                
                            </tr>
                            <tr>
                                <td>Viva</td>
                                <td *ngIf="row.settings.has_viva == 1">{{examData.types[2].max_score}}</td>
                                <td *ngIf="row.settings.has_viva == 0">-</td>
                                <td>-</td>
                                <td *ngIf="row.settings.has_viva == 1">{{row.scores[2].score}}</td>
                                <td *ngIf="row.settings.has_viva == 0">-</td>
                                <td *ngIf="row.settings.has_practical_viva == 1">{{examData.types[6].max_score}}</td>
                                <td *ngIf="row.settings.has_practical_viva == 0">-</td>
                                <td>-</td>
                                <td *ngIf="row.settings.has_practical_viva == 1">{{row.scores[6].score}}</td>
                                <td *ngIf="row.settings.has_practical_viva == 0">-</td>
                                
                                
                                
                            </tr>
                            <tr>
                                <td>Group Total</td>
                                <td>{{row.theory_max}}</td>
                                <td>{{row.theory_min}}</td>
                                <td>{{row.theory_total}}</td>
                                <td *ngIf="row.settings.has_practical == 1">{{row.pr_max}}</td>
                                <td *ngIf="row.settings.has_practical == 0">-</td>
                                <td *ngIf="row.settings.has_practical == 1">{{row.pr_min}}</td>
                                <td *ngIf="row.settings.has_practical == 0">-</td>
                                <td *ngIf="row.settings.has_practical == 1">{{row.pr_total}}</td>
                                <td *ngIf="row.settings.has_practical == 0">-</td>
                                
                                
                                
                            </tr>
                        </ng-container>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="9" class="text-center">
                                <strong>Grand Total</strong>
                            </td>
                            <td>{{examData.grandMax}}</td>
                            <td>{{examData.grandMin}}</td>
                            <td>{{examData.grandTotal}}</td>
                            <td>
                                <strong>{{examData.final_result}}</strong>
                                <!--<ng-container *ngIf="examData.grandTotal >= examData.grandMin">
                                    Passed
                                </ng-container>
                                <ng-container *ngIf="examData.grandTotal < examData.grandMin">
                                    Failed
                                </ng-container>-->
                            </td>
                        </tr>
                    </tfoot>

                </table>
                
                
            </ng-container>

            <ng-container *ngIf="selectedView == 'fee'">
                <h4 class="mb-4">
                    Fee Payments &nbsp;
                    
                    <a class="btn btn-sm btn-action" href="#modal-new-payment" data-toggle="modal"(click)=loadSemesters()>New</a>

                </h4>

                <ng-container *ngIf="feePayments.length > 0; else noPaymentsData">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <th>Year</th>
                            <th>Paid Amount</th>
                            <th>Payment Date</th>
                            <th>Receipt No.</th>
                            
                        </thead>
                        <tbody>
                            <tr *ngFor="let fp of feePayments">
                                <td>{{fp.semester.name}}</td>
                                <td> {{fp.paid_amount}} </td>
                                <td> {{fp.payment_date}} </td>
                                <td *ngIf="fp.receipt_no != null && fp.receipt_no != ''"> 
                                    {{fp.receipt_no}} 
                                </td>
                                <td *ngIf="fp.receipt_no == null || fp.receipt_no == ''"> 
                                    <a href="javascript:void(0);" (click)="inputReceiptNo(fp)">Add receipt no.</a>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </ng-container>

                <ng-template #noPaymentsData>
                    <p class="text-center text-muted">No data to display</p>
                </ng-template>
            </ng-container>

        </div>

        

        

    </div>
</div>


<!-- modals -->

<div class="modal fade" id="modal-upload-photo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Upload Photo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fphoto="ngForm" (ngSubmit)="submitPhoto(fphoto.value)" id="formPhoto" ngNativeValidate>
                
                <div class="form-group">
                    <label class="control-label">Select photo</label>
                    <div class="col-12">
                      <input type="file" class="form-control" name="file" (change)="onFileSelect($event)" accept=".jpg, .jpeg, .png, .gif, .webp" required/>
                      
                    </div>
                </div>

                <p class="text-center size-warning" *ngIf="uploadSize != ''">
                    File size is {{uploadSize}}. 
                    <ng-container *ngIf="uploadFile.size >= 2097152">
                      You can't upload files greater than 50 MB
                    </ng-container>
                </p>

                <p class="text-center" *ngIf="mediaUrl != null && mediaUrl != ''">
                    <img *ngIf="mediaType == 'image'" class="img-fluid img-thumbnail" [src]="mediaUrl"/>
        
                </p>
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!uploading" form="formPhoto" class="btn btn-action">Upload</button>
          <span class="spinner" *ngIf="uploading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>




<div class="modal fade" id="modal-edit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Student</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fedit="ngForm" (ngSubmit)="edit(fedit.value)" id="formEdit" ngNativeValidate>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="">Name</label>
                        <input class="form-control" type="text" [(ngModel)]="data.name" name="name" required>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="">College ID</label>
                        <input class="form-control" type="text" [(ngModel)]="data.student_id" name="student_id" required>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="">University ID </label>
                        <input class="form-control" type="text" [(ngModel)]="data.university_id" name="university_id" required>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="">Category</label>
                        <select [(ngModel)]="data.category.id" name="category_id" class="form-control">
                          <option *ngFor="let c of feeCategories" value="{{c.id}}">{{c.name}}</option>
                        </select>
                    </div>  
    
                    <div class="col-md-6 mb-3">
                        <label for="">address</label>
                        <input class="form-control" type="text" [(ngModel)]="data.address" name="address" >
                    </div>

                    
    
                    <div class="col-md-6 mb-3">
                        <label for="">city</label>
                        <input class="form-control" type="text" [(ngModel)]="data.city" name="city" >
                    </div>
    
                    <div class="col-md-6 mb-3">
                        <label for="">DOB</label>
                        <input class="form-control" type="date" [(ngModel)]="data.dob" name="dob" >
                    </div>
    
                    <div class="col-md-6 mb-3">
                        <label for="">phone</label>
                        <input class="form-control" type="text" [(ngModel)]="data.phone" name="phone" required>
                    </div>
    
                    <div class="col-md-6 mb-3">
                        <label for="">email</label>
                        <input class="form-control" type="text" [(ngModel)]="data.email" name="email" >
                    </div>
    
                    <div class="col-md-6 mb-3">
                        <label for="">guardian name</label>
                        <input class="form-control" type="text" [(ngModel)]="data.guardian.name" name="guardian_name" >
                    </div>
    
                    <div class="col-md-6 mb-3">
                        <label for="">guardian address</label>
                        <input class="form-control" type="text" [(ngModel)]="data.guardian.address" name="guardian_address" >
                    </div>
                    
                    <div class="col-md-6 mb-3">
                        <label for="">guardian phone</label>
                        <input class="form-control" type="text" [(ngModel)]="data.guardian.phone" name="guardian_phone">
                    </div>
                </div>
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formEdit" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>


<!-- new payment -->

<div class="modal fade" id="modal-new-payment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">New payment</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fpayment="ngForm" (ngSubmit)="submitPayment(fpayment.value)" id="formPayment" ngNativeValidate>
                
                <div class="form-group">
                    <label class="control-label">Select year</label>
                    <select [(ngModel)]="selectedFeeSemester" name="semester_id" class="form-control" (change)="autoloadFee($event.target.value)" required>
                        <option *ngFor="let semester of semesters" value="{{semester.id}}">{{semester.name}}</option>
                    </select>
                </div>

                <p class="mb-4" *ngIf="feeAmount != ''">
                    Fee Amount: {{feeAmount}} | Already Paid: {{paidFee}} | Balance: {{balanceFee}}
                </p>

                <div class="form-group">
                    <label class="control-label">Amount Paid</label>
                    <input type="text" [(ngModel)]="balanceFee" name="paid_amount" class="form-control" required>
                </div>
                

                <div class="form-group">
                    <label class="control-label">Date</label>
                    <input type="date" ngModel name="payment_date" class="form-control" required>
                </div>

                <div class="form-group">
                    <label class="control-label">Receipt No.</label>
                    <input type="text" ngModel name="receipt_no" class="form-control">
                </div>
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formPayment" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>