<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Timetable - {{batch.course}} &nbsp; <br>
                <small>Batch: {{batch.start_year}}</small>  &nbsp;
                <small>Current Year: {{batch.current_semester}}</small>
                </h3>
            </div>

            <div class="col-md-6 text-right">
               
            </div>
        </div>

        <div class="page-body">

            <table class="table table-bordered">
                <thead>
                    <th></th>
                    <th *ngFor="let day of data.days">{{day.day}}</th>
                </thead>

                <tbody>
                    <tr *ngFor="let session of data.sessions">
                        <td>
                            {{session.start_time}} - {{session.end_time}}
                        </td>
                        <td *ngFor="let day of data.days" class="class-slot">
                           <ng-container *ngIf="session.is_break == 0">
                            
                                <div 
                                    class="slot-text" 
                                    [ngClass]="(data.data[day.id][session.id].topic_id == 0) ? 'empty' : ''" 
                                    data-target="#modal-slot"
                                    data-toggle="modal"
                                    (click)="selectSlot(data.data[day.id][session.id], day.id, session.id)"
                                >

                                    {{data.data[day.id][session.id].subject}}

                                    <small *ngIf="data.data[day.id][session.id].faculty.id != 0"> <br>({{data.data[day.id][session.id].faculty.name}}) </small>
                                    <small *ngIf="data.data[day.id][session.id].faculty.id == 0"> <br>&nbsp; </small>
                                </div>

                                <ng-container *ngIf="data.data[day.id][session.id].topic_id != 0">
                                    <small class="pl-2">
                                        <a href="javascript:void(0);" routerLink="/dashboard/subject-attendance/{{data.data[day.id][session.id].id}}">Mark attendance</a>
                                    </small>
                                </ng-container>

                           </ng-container>
                           <ng-container *ngIf="session.is_break == 1">
                                BREAK
                            </ng-container>
                        </td>
                    </tr>
                </tbody>

            </table>

        </div>
    </div>
</div>


<!-- modals -->

<!-- manage slot -->

<div class="modal fade" id="modal-slot" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Manage slot</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fslot="ngForm" (ngSubmit)="modifySlot(fslot.value)" id="formSlot" ngNativeValidate>
                <div class="form-group">
                    <label for="">Topic</label>
                    <select [(ngModel)]="selectedSlot.topic_id" name="topic_id" class="form-control" (change)="setDisplayText($event.target.value)" required>
                        <option *ngFor="let topic of topics" value="{{topic.id}}">{{topic.topic}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="">Display text</label>
                    <input class="form-control" type="text" [(ngModel)]="selectedSlot.subject" name="subject">
                </div>

                <div class="form-group">
                    <label for="">Faculty</label>
                    <select [(ngModel)]="selectedSlot.faculty.id" name="faculty_id" class="form-control" (change)="setSlotFacultyName($event.target.value)">
                        <option value="0">Not selected</option>
                        <option *ngFor="let faculty of facultiesDrop" value="{{faculty.id}}">{{faculty.name}}</option>
                    </select>
                </div>

                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formSlot" class="btn btn-action">Submit</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>