<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-6">
                <h3>Fee Payments ({{data.count}})</h3>
            </div>

            <div class="col-md-4"></div>
            <div class="col-md-2 text-right">
                <input type="date" class="form-control" [(ngModel)]="date" (change)="loadData()">
                <small>filter by date</small>
            </div>
        </div>

        <div class="page-body">
            <table class="table table-borderless table-striped">
                <thead>
                    <th>Student Name</th>
                    <th>Batch</th>
                    <th>Year (payment made for)</th>
                    <th>Paid amount</th>
                    <th>Date of payment</th>
                    <th>Receipt No.</th>
                </thead>
                <tbody>
                    <ng-container *ngIf="data.list.length > 0; else noData">
                        <tr *ngFor="let row of data.list">
                            <td>
                                {{row.student.name}}
                                <small *ngIf="row.category.name.length > 0" class="badge badge-dark">{{row.category.name}}</small>
                            </td>
                            <td>{{row.student.batch.name}}</td>
                            <td>{{row.semester.name}}</td>
                            <td>{{row.paid_amount}}</td>
                            <td>{{row.payment_date}}</td>
                            <td>{{row.receipt_no}}</td>
                        </tr>
                    </ng-container>

                    <ng-template #noData>
                        <tr>
                            <td colspan="6" class="text-center">
                                No data to show!    
                            </td>
                            
                        </tr>
                    </ng-template>
                </tbody>
            </table>
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6">
                  <img src="assets/img/icon-arrow-left-active.svg" class="btn btn-nav" *ngIf="offset > 0" (click)="paginate(1)" />
                </div>
                <div class="col-md-6 text-right">
                  <img src="assets/img/icon-arrow-right-active.svg" class="btn btn-nav" *ngIf="data.count > offset+limit" (click)="paginate(2)" />
                </div>
            </div>
        </div>
    </div>
</div>