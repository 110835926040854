import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-fee-structure',
  templateUrl: './fee-structure.component.html',
  styleUrls: ['./fee-structure.component.scss']
})
export class FeeStructureComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id:any = 0;
  data:any = [];
  batch:any = {class_teachers:[]};

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadBatch();
    this.loadData();
  }

  loadBatch(){
    this.dataService.getBatch(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.batch = data;
      }
      
    } );
  }

  loadData(){
    this.dataService.getFeeStructure(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data.data;
        
      }
      
    } );
  }

  updateFeeRow(row, sem){
    
    let data = {
      batch_id:this.id,
      semester_id: sem.semester.id,
      category_id: row.category.id,
      fee: row.fee,
      seats:row.seats
    }

    this.dataService.postUpdateFeeStructure(data).subscribe( (res:any) => {
      
      if(res.status == 0) {
        //@ts-ignore
        row.total = res.total;

      } else {
        alert(res.msg);
      }

    });

  }

}
