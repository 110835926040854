<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-8">
                <h3>Syllabus</h3>
            </div>
        </div>

        <div class="page-body">

            <ng-container *ngFor="let course of data.courses">
                

                <div class="row">
                    <div class="col-md-3">
                        <h4>{{course.name}}</h4>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-sm btn-action" routerLink="/dashboard/syllabus/{{course.id}}">View Syllabus</button>
                    </div>
                </div>

                <hr>
            </ng-container>


        </div>

    </div>

    
</div>