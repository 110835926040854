<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-1 pt-3">
              <img *ngIf="photo_url == 0" src="assets/img/user-placeholder.png" alt="">
              <img *ngIf="photo_url != 0" [src]="photo_url" alt="">
            </div>
            <div class="col-10">
                <h3 class="">{{user.name}} <small>({{user.user_name}})</small> </h3>
                <h6>Role: {{user.role}}</h6>
            </div>
        </div>

        <div class="page-body">
            
            <form #f1="ngForm" (ngSubmit)="submitForm(f1.value)" id="form1" ngNativeValidate>

                <div class="row ind-row">
                  <div class="col-md-6">
                    <label>Name As Per Bank</label>
                    <input type="text" [(ngModel)]="data.name_bank" name="name_bank" class="form-control"/>
                  </div>
        
                  <div class="col-md-6">
                    <label>Designation</label>
                    <input type="text" [(ngModel)]="data.designation" name="designation" class="form-control"/>
                  </div>
        
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Department</label>
                    <input type="text" [(ngModel)]="data.department" name="department" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Date of Birth</label>
                    <input type="date" [(ngModel)]="data.dob" name="dob" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Gender</label>
                    <input type="text" [(ngModel)]="data.gender" name="gender" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Date of Joining NIPMR</label>
                    <input type="date" [(ngModel)]="data.date_join" name="date_join" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Marital Status</label>
                    <input type="text" [(ngModel)]="data.marital_status" name="marital_status" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Spouse's Name</label>
                    <input type="text" [(ngModel)]="data.husband_name" name="husband_name" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Father's name</label>
                    <input type="text" [(ngModel)]="data.father_name" name="father_name" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Blood Group</label>
                    <input type="text" [(ngModel)]="data.blood_group" name="blood_group" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Bank Account Number</label>
                    <input type="text" [(ngModel)]="data.bank_account" name="bank_account" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>IFS Code</label>
                    <input type="text" [(ngModel)]="data.bank_ifsc" name="bank_ifsc" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>PAN Number</label>
                    <input type="text" [(ngModel)]="data.pan" name="pan" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Aadhaar Number</label>
                    <input type="text" [(ngModel)]="data.aadhaar" name="aadhaar" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Hobbies & Interests</label>
                    <input type="text" [(ngModel)]="data.hobbies" name="hobbies" class="form-control"/>
                  </div>
                  
                </div>
        
                <hr/>
                <p class="form-sub">Contract Validity Details</p>
                <div class="row">
                  <div class="col-md-6">
                    <label>Contract Start Date</label>
                    <input type="date" [(ngModel)]="data.contract_start" name="contract_start" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Contract End Date</label>
                    <input type="date" [(ngModel)]="data.contract_end" name="contract_end" class="form-control"/>
                  </div>
                </div>
        
                <hr/>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Permanent Address</label>
                    <input type="text" [(ngModel)]="data.address1" name="address1" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Pin Code</label>
                    <input type="text" [(ngModel)]="data.pin1" name="pin1" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Present Address</label>
                    <input type="text" [(ngModel)]="data.address2" name="address2" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Pin Code</label>
                    <input type="text" [(ngModel)]="data.pin2" name="pin2" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Mobile Number</label>
                    <input type="text" [(ngModel)]="data.mobile" name="mobile" class="form-control"/>
                  </div>
                  
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Emergency Contact Name</label>
                    <input type="text" [(ngModel)]="data.emergency_name" name="emergency_name" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Emergency Contact Number</label>
                    <input type="text" [(ngModel)]="data.emergency_no" name="emergency_no" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Passport Number</label>
                    <input type="text" [(ngModel)]="data.passport_no" name="passport_no" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Place of Issue</label>
                    <input type="text" [(ngModel)]="data.place_of_issue" name="place_of_issue" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Date of Issue</label>
                    <input type="date" [(ngModel)]="data.date_issue" name="date_issue" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Date of Expiry</label>
                    <input type="date" [(ngModel)]="data.date_expiry" name="date_expiry" class="form-control"/>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-6">
                    <label>Email ID - Personal</label>
                    <input type="text" [(ngModel)]="data.email_personal" name="email_personal" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Email ID - NIPMR (If any)</label>
                    <input type="text" [(ngModel)]="data.email_nipmr" name="email_nipmr" class="form-control"/>
                  </div>
                </div>
        
                <hr/>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Mother tongue</label>
                    <input type="text" [(ngModel)]="data.mother_tongue" name="mother_tongue" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Proficiency</label>
                    <input type="text" [(ngModel)]="data.proficiency" name="proficiency" class="form-control"/>
                  </div>
                </div>
        
                <p class="form-sub">Languages Known</p>
                <div class="row more-row" *ngFor="let item of more_data['10']; let i = index">
                  <div class="col-md-6" >
                    <label>Language</label>
                    <input type="text" class="form-control" value="{{item.content.language}}" (input)="updateJsonField('10', i, 'language', $event.target.value)" />  
                  </div>
                  <div class="col-md-6">
                    <label>Proficiency</label>
                    <input type="text" class="form-control" value="{{item.content.proficiency}}" (input)="updateJsonField('10', i, 'proficiency', $event.target.value)" />  
                    <a class="btn-rem" (click)="removeRow('10',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                  
                </div>
                <a href="javascript:void(0);" (click)="addNewLang()">Add New</a>
        
                <hr/>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Professional Registration Number</label>
                    <input type="text" [(ngModel)]="data.reg_no" name="reg_no" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Issuing Authority</label>
                    <input type="text" [(ngModel)]="data.issuing_authority" name="issuing_authority" class="form-control"/>
                  </div>
                </div>
        
                <div class="row">
                  <div class="col-md-6">
                    <label>Registration Date</label>
                    <input type="date" [(ngModel)]="data.registration_date" name="registration_date" class="form-control"/>
                  </div>
                  <div class="col-md-6">
                    <label>Registration Valid Upto</label>
                    <input type="date" [(ngModel)]="data.registration_upto" name="registration_upto" class="form-control"/>
                  </div>
                </div>
        
                <hr/>
                <p class="form-sub">Academic Qualifications</p>
                <div class="row more-row">
                  <div class="col-md-4">
                    <p class="form-sub-sub">Name of the course</p>
                    
                  </div>
                  <div class="col-md-4">
                    <p class="form-sub-sub">Subject / Major / Specialization</p>
                    
                  </div>
                  <div class="col-md-4">
                    <p class="form-sub-sub">Year of Passing</p>
                    
                  </div>
                </div>
                <div class="row more-row" *ngFor="let item of more_data['8']; let i = index">
                  <div class="col-md-4">
                    
                    <input type="text" class="form-control" value="{{item.content.course}}" (input)="updateJsonField('8', i, 'course', $event.target.value)" />
                  </div>
                  <div class="col-md-4">
                    
                    <input type="text" class="form-control" value="{{item.content.subject}}" (input)="updateJsonField('8', i, 'subject', $event.target.value)" />
                  </div>
                  <div class="col-md-4">
                    
                    <input type="text" class="form-control" value="{{item.content.year}}" (input)="updateJsonField('8', i, 'year', $event.target.value)" />
                    <a class="btn-rem" (click)="removeRow('8',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                </div>
        
                <a href="javascript:void(0);" (click)="addNewAc()">Add New</a>
        
                <hr/>
                <p class="form-sub">Work Experience</p>
        
                <div class="row more-row">
                  <div class="col-md-2">
                    <p class="form-sub-sub">Title</p>
                    
                  </div>
                  <div class="col-md-2">
                    <p class="form-sub-sub">Employment Type</p>
                    
                  </div>
                  <div class="col-md-2">
                    <p class="form-sub-sub">Company</p>
                    
                  </div>
                  <div class="col-md-2">
                    <p class="form-sub-sub">Location</p>
                    
                  </div>
                  <div class="col-md-1">
                    <p class="form-sub-sub" style="font-size:10px">Start Date</p>
                    
                  </div>
                  <div class="col-md-1">
                    <p class="form-sub-sub">End Date</p>
                    
                  </div>
                  <div class="col-md-2">
                    <p class="form-sub-sub">Last salary drawn</p>
                    
                  </div>
        
                </div>
        
                <div class="row more-row" *ngFor="let item of more_data['9']; let i = index">
                  <div class="col-md-2">
                    <input type="text" class="form-control" value="{{item.content.title}}" (input)="updateJsonField('9', i, 'title', $event.target.value)" />
                    
                  </div>
                  <div class="col-md-2">
                    <input type="text" class="form-control" value="{{item.content.type}}" (input)="updateJsonField('9', i, 'type', $event.target.value)" />
                    
                  </div>
                  <div class="col-md-2">
                    <input type="text" class="form-control" value="{{item.content.company}}" (input)="updateJsonField('9', i, 'company', $event.target.value)" />
                    
                  </div>
                  <div class="col-md-2">
                    <input type="text" class="form-control" value="{{item.content.location}}" (input)="updateJsonField('9', i, 'location', $event.target.value)" />
                    
                  </div>
                  <div class="col-md-1">
                    <input type="text" class="form-control" value="{{item.content.start}}" (input)="updateJsonField('9', i, 'start', $event.target.value)" />
                    
                  </div>
                  <div class="col-md-1">
                    <input type="text" class="form-control" value="{{item.content.end}}" (input)="updateJsonField('9', i, 'end', $event.target.value)" />
                    
                  </div>
                  <div class="col-md-2">
                    <input type="text" class="form-control" value="{{item.content.salary}}" (input)="updateJsonField('9', i, 'salary', $event.target.value)" />
                    <a class="btn-rem" style="font-size:20px;" (click)="removeRow('9',i, item.id)" href="javascript:void(0);">&times;</a>
                    
                  </div>
        
                </div>
                <a href="javascript:void(0);" (click)="addNewWork()">Add New</a>
        
                <hr/>
        
                <p class="form-sub">Publication Details</p>
                <p class="form-sub-sub">Topics</p>
        
                <div class="row more-row" *ngFor="let item of more_data['1']; let i = index">
                  <div class="col-md-8">
                    
                    <input type="text" class="form-control" value="{{item.content}}" (input)="updateField('1', i, $event.target.value)"/>
                    <a class="btn-rem" (click)="removeRow('1',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="addNew('1')">Add New</a>
        
                <p class="form-sub-sub">Web links (if any)</p>
        
                <div class="row more-row" *ngFor="let item of more_data['2']; let i = index">
                  <div class="col-md-8">
                    
                    <input type="text" class="form-control" value="{{item.content}}" (input)="updateField('2', i, $event.target.value)"/>
                    <a class="btn-rem" (click)="removeRow('2',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="addNew('2')">Add New</a>
        
                <hr/>
        
                <p class="form-sub">Training Achieved</p>
                <div class="row more-row" *ngFor="let item of more_data['3']; let i = index">
                  <div class="col-md-8">
                    
                    <input type="text" class="form-control" value="{{item.content}}" (input)="updateField('3', i, $event.target.value)"/>
                    <a class="btn-rem" (click)="removeRow('3',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="addNew('3')">Add New</a>
        
                <hr/>
        
                <p class="form-sub">Awards/Recognitions</p>
                <div class="row more-row" *ngFor="let item of more_data['4']; let i = index">
                  <div class="col-md-8">
                    
                    <input type="text" class="form-control" value="{{item.content}}" (input)="updateField('4', i, $event.target.value)"/>
                    <a class="btn-rem" (click)="removeRow('4',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="addNew('4')">Add New</a>
        
                <hr/>
        
                <p class="form-sub">Seminars / Worshops attended</p>
                <div class="row more-row" *ngFor="let item of more_data['5']; let i = index">
                  <div class="col-md-8">
                    
                    <input type="text" class="form-control" value="{{item.content}}" (input)="updateField('5', i, $event.target.value)"/>
                    <a class="btn-rem" (click)="removeRow('5',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="addNew('5')">Add New</a>
        
                <hr/>
        
                <p class="form-sub">Positions Held</p>
                <p class="form-sub-sub">Past</p>
                <div class="row more-row" *ngFor="let item of more_data['6']; let i = index">
                  <div class="col-md-8">
                    
                    <input type="text" class="form-control" value="{{item.content}}" (input)="updateField('6', i, $event.target.value)"/>
                    <a class="btn-rem" (click)="removeRow('6',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="addNew('6')">Add New</a>
        
                <p class="form-sub-sub">Current</p>
                <div class="row more-row" *ngFor="let item of more_data['7']; let i = index">
                  <div class="col-md-8">
                    
                    <input type="text" class="form-control" value="{{item.content}}" (input)="updateField('7', i, $event.target.value)"/>
                    <a class="btn-rem" (click)="removeRow('7',i, item.id)" href="javascript:void(0);">&times;</a>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="addNew('7')">Add New</a>
        
              </form>
        
             <p style="margin-top: 20px;">
              <input type="submit" class="btn btn-action" value="Save" form="form1" />
             </p> 

        </div>
    </div>
</div>