import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-examinations',
  templateUrl: './examinations.component.html',
  styleUrls: ['./examinations.component.scss']
})
export class ExaminationsComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  loading:any = false;
  data:any = [];
  batchId:any = 0;

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.batchId = this.route.snapshot.paramMap.get('batchId');
    this.dataService.getBatchExams(this.batchId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data.data;
      }
      
    } );
  }


  

  

  

  

  
}
