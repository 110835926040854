import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  batches:any = [];
  semesters:any = [];

  ngOnInit(): void {
    this.loadBatches();
  }

  loadBatches(){
    this.dataService.getBatches().subscribe( (data: any )=> {
      if(data.status == 0){
        this.batches = data.list;
      }
      
    } );
  }

  loadSemesters(batchId){
    this.dataService.getBatchSemesters(batchId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.semesters = data.list;
      }
      
    } );
  }



  feeTobePaid(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/fee_tobe_paid&batch_id="+data.batch_id+"&sem_id="+data.sem_id+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

  feePaid(data){
    this.dataService.getGenerateDisposableToken().subscribe( (res:any) => {
      if(res.status == 0) {
        
        window.open(this.dataService.REST_API_SERVER + "reports/fee_paid&batch_id="+data.batch_id+"&sem_id="+data.sem_id+"&token="+res.token)

      } else {
        alert(res.msg);
      }
    });
  }

}
