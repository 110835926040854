<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-8">
                <h3>Timetables</h3>
            </div>
        </div>

        <div class="page-body">

            <ng-container *ngFor="let course of data.courses">
                <h4>{{course.name}}</h4>

                <div class="row">
                    <div class="col-md-3" *ngFor="let batch of course.batches">
                        <div class="btn-timetable" routerLink="/dashboard/time-table/{{batch.id}}">
                          <h6>Batch:  {{batch.start_year}}</h6>
                            
                          <small>Click to view timetable</small>
                        </div>
                    </div>
                </div>

                <hr>
            </ng-container>


        </div>

    </div>

    
</div>