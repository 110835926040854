<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 offset-md-3 col-sm-12 offset-sm-0">
            <div class="login-page">
          
              <div class="login-box">
                <div class="logo-box text-center">
                  <img src="assets/img/logo-nipmr-color.png"/>
                  <p style="color: #3f3e3e">
                    College Management System
                  </p>
                </div>
                  
                  
                  <p>{{"Please login to your account"}}</p>
          
                  <form #flogin="ngForm" (ngSubmit)="login(flogin.value)" ngNativeValidate>
                      <fieldset>
                        <div class="form-group" style="margin-bottom: 10px;">
                          <label for="disabledTextInput">Username</label>
                          <input type="text" class="form-control" placeholder="Your username" name="username" required="true" ngModel>
                        </div>
                        <div class="form-group">
                          <label for="disabledSelect">Password</label>
                          <input type="password" class="form-control" placeholder="Enter password" name="password" required="true" ngModel>
                        </div>
                        
                        <div class="form-group">
                          
                            <span class="cursor-pointer" (click)="toggleMainLogin()">
                              <i class="bi bi-square" *ngIf="!mainLogin"></i>
                              <i class="bi bi-check-square-fill" *ngIf="mainLogin"></i>
                              &nbsp;
                              Login with NIPMR credentials
                            </span>
                          
                        </div>
                        
          
                        <br/>
                        
                        <div class="button-div" *ngIf="!loading">
                            <button type="submit" class="btn btn-primary" id="btn-login">Login</button>
          
                        </div>
                        <div class="text-center" *ngIf="loading" >
                            <i class="fa-solid fa-spinner fa-spin"></i>
                        </div>
                        
                        
                      </fieldset>
                    </form>
                    
          
              </div>
            </div>
          </div>
    
        
    </div>
</div>