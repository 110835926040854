import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-syllabus',
  templateUrl: './syllabus.component.html',
  styleUrls: ['./syllabus.component.scss']
})
export class SyllabusComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  
  courseId:any = 0;
  data:any = [];
  course:any = {name:""}
  faculties:any = [];
  selectedSubject:any = {id:0, ti:""}
  selectedModule:any = {id:0, name:""}
  loading:any = false;

  ngOnInit(): void {
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.loadData();
    this.loadCourse();
    this.loadFaculties();
  }

  loadData(){
    this.dataService.getSyllabus(this.courseId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data.data;
      }
      
    } );
  }
  
  loadCourse(){
    this.dataService.getCourse(this.courseId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.course = data;
      }
      
    } );
  }

  inputTopic(semester_id, i, j){
    let topic = prompt("Subject name", "");
    if(topic != null){
      let data = {
        course_id:this.courseId,
        semester_id:semester_id,
        topic:topic
      }

      this.dataService.postSyllabusTopic(data).subscribe( (res:any) => {
        if(res.status == 0) {
          console.log(this.data[i].syllabus);
          this.data[i].syllabus.push({id:res.id, topic:topic})
        } else {
          alert(res.msg);
        }
  
      });

    }
  }

  editTopic(topic){
    let topicText = prompt("Subject name", topic.topic);
    if(topicText != null){
      let data = {
        topic_id:topic.id,
        topic:topicText
      }

      this.dataService.postEditSyllabusTopic(data).subscribe( (res:any) => {
        if(res.status == 0) {
          topic.topic = topicText;
        } else {
          alert(res.msg);
        }
  
      });

    }
  }

  deleteTopic(topic){
    let cnf = confirm("Do you really want to delete " + topic.topic + "?");
    if(cnf){
      let data = {
        topic_id:topic.id,
        
      }

      this.dataService.postDeleteSyllabusTopic(data).subscribe( (res:any) => {
        if(res.status == 0) {
          this.loadData();
        } else {
          alert(res.msg);
        }
  
      });

    }
  }

  loadFaculties(){
    this.dataService.getFacultyList().subscribe( (data: any )=> {
      if(data.status == 0){
        this.faculties = data.list;
      }
      
    } );
  }

  assignFaculty(data){
    data.subject_id = this.selectedSubject.id;
    
    this.dataService.postAssignSubjectFaculty(data).subscribe( (res:any) => {
      if(res.status == 0) {
        this.loadData();
        //@ts-ignore
        $("#modal-assign-faculty").modal("hide");
      } else {
        alert(res.msg);
      }

    });
  }

  assignModuleFaculty(data){
    data.module_id = this.selectedModule.id;
    
    this.dataService.postAssignModuleFaculty(data).subscribe( (res:any) => {
      if(res.status == 0) {
        this.loadData();
        //@ts-ignore
        $("#modal-assign-module-faculty").modal("hide");
      } else {
        alert(res.msg);
      }

    });
  }

  selectSubject(subject){
    this.selectedSubject = subject;
  }

  selectModule(module){
    this.selectedModule = module;
  }

  addModule(subject){
    let input = prompt("Enter module name", "");

    if(input){
      let data = {
        subject_id: subject.id,
        name: input
      }

      this.dataService.postSubjectModule(data).subscribe( (res:any) => {
        if(res.status == 0) {
          this.loadData();
          //@ts-ignore
          
        } else {
          alert(res.msg);
        }
  
      });
    }

    

  }

  editModule(module){
    let moduleText = prompt("Module name", module.name);
    if(moduleText != null){
      let data = {
        module_id:module.id,
        module:moduleText
      }

      this.dataService.postEditSyllabusModule(data).subscribe( (res:any) => {
        if(res.status == 0) {
          module.name = moduleText;
        } else {
          alert(res.msg);
        }
  
      });

    }
  }

  deleteModule(module){
    let cnf = confirm("Do you really want to delete " + module.name + "?");
    if(cnf){
      let data = {
        module_id:module.id,
        
      }

      this.dataService.postDeleteSyllabusModule(data).subscribe( (res:any) => {
        if(res.status == 0) {
          this.loadData();
        } else {
          alert(res.msg);
        }
  
      });

    }
  }

}
