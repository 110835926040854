import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-faculties',
  templateUrl: './faculties.component.html',
  styleUrls: ['./faculties.component.scss']
})
export class FacultiesComponent implements OnInit {

  constructor(private dataService: DataService) { }
  loading:any = false;
  data:any = {list:[], count:0};
  limit:any = 30;
  offset:any = 0;
  search:any = "";
  roles:any = [];
  selectedUser:any = {};


  ngOnInit(): void {
    this.loadData();
    this.loadRoles();
  }

  loadData(){
    this.dataService.getFaculties(this.limit, this.offset, this.search).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
    } );
  }

  loadRoles(){
    this.dataService.getRoles().subscribe( (data: any )=> {
      if(data.status == 0){
        this.roles = data.roles;
      }
    } );
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadData();

  }


  submitFaculty(data){
    this.loading = true;
    
    
    this.dataService.postFaculty(data).subscribe( (res:any = {status:0, msg:""}) => {
      this.loading = false;

      if(res.status == 0){
        this.loadData();
        //@ts-ignore
        $("#modal-new-faculty").modal("hide");
      } else {
        alert(res.msg);
      }

    }); 
  }

  selectFaculty(user){
    this.selectedUser = user;
  }

  editUser(data){
    this.loading = true;
    data.user_id = this.selectedUser.id;
    this.dataService.postEditFaculty(data).subscribe( (res:any = {status:0, msg:""}) => {
      this.loading = false;

      if(res.status == 0){
        this.loadData();
        //@ts-ignore
        $("#modal-edit-faculty").modal("hide");
      } else {
        alert(res.msg);
      }

    });
  }


  deleteUser(user){
    var cnf = confirm("Do you really want to delete " + user.name + "?" );

    if(cnf){
      let data = {user_id:user.id}
      this.dataService.postDeleteFaculty(data).subscribe( (res:any = {status:0, msg:""}) => {
        this.loading = false;
  
        if(res.status == 0){
          this.loadData();
          
        } else {
          alert(res.msg);
        }
  
      });
    }

    
  }

}
