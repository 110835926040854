import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id:any = 0;
  data:any = {
    id:0, 
    name:"",
    guardian:{},
    batch:{},
    category:{}
  }
  photo_url:any = 0;
  uploading:any = false;
  uploadFile:any;
  uploadSize:any = "";
  mediaUrl:any = null;
  mediaType:any = null;
  loading:any = false;

  scores:any = [];
  feePayments:any = [];
  semesters:any = [];
  selectedFeeSemester:any = "";
  feeAmount:any = "";
  paidFee:any = "";
  balanceFee:any = "";
  feeCategories:any = [];
  profile:any = {}
  selectedView:any = "details";
  examData:any = [];
  selectedSem:any = 0;

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadData();
    this.loadExamScores();
    this.loadFeePayments();
    this.loadFeeCategories();
    this.loadFullProfile();
    
  }

  loadData(){
    this.dataService.getStudent(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data.profile;
        this.loadSemesters();
        if(data.profile.photo_url != null && data.profile.photo_url != ""){
          this.loadProfilePhoto();
        }
      }
      
    } );
  }

  loadFullProfile(){
    this.dataService.getStudentData(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.profile = data.profile;
      }
      
    } );
  }

  loadFeeCategories(){
    this.dataService.getFeeCategories().subscribe( (data: any )=> {
      this.feeCategories = data;
      
    } );
  }

  loadProfilePhoto(){
    this.dataService.getStudentPhoto(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.photo_url = data.photo_url;
        
      }
      
    } );
  }

  loadExamScores(){
    this.dataService.getStudentScores(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.scores = data.list;
      }
      
    } );
  }

  loadFeePayments(){
    this.dataService.getFeePayments(this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.feePayments = data.list;
      }
      
    } );
  }

  loadSemesters(){
    this.feeAmount = "";
    this.dataService.getBatchSemesters(this.data.batch.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.semesters = data.list;
        
      }
      
    } );
  }

  onFileSelect(e){
    this.uploadFile = e.target.files[0];
    

    let uploadSize = "";
    if(this.uploadFile.size > 1000 && this.uploadFile.size < 1000000 ){
      uploadSize = (this.uploadFile.size / 1000).toFixed(1) + " KB";

    } else if(this.uploadFile.size >= 1000000){
      uploadSize = (this.uploadFile.size / 1000000).toFixed(1) + " MB";
    }
     else {
      uploadSize = this.uploadFile.size + " Bytes";
    }

    this.uploadSize = uploadSize;

    //media preview
    const mimeType = this.uploadFile.type;

    if (mimeType.match(/image\/*/) !== null) {
      //image
      this.mediaType = "image";
      
    }else if (mimeType.match(/video\/*/) !== null) {
      //video
      this.mediaType = mimeType;
      
    } else {
      //
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.uploadFile); 
    reader.onload = (_event) => { 
        this.mediaUrl = reader.result; 
    }
    

  }

  submitPhoto(data){
    if(this.uploadFile.size < 2097152){
      const formData = new FormData();
      formData.append('file', this.uploadFile, this.uploadFile.name);
      formData.append("student_id", this.id);
      this.uploading = true;

      this.dataService.postStudentPhoto(formData).subscribe( (res:any = {status:0, msg:""}) => {
        this.uploading = false;
        if(res.status == 0) {
          this.loadData();
          //@ts-ignore
          $("#modal-upload-photo").modal("hide");
        } else {
          alert(res.msg);
        }
      
      });

    } else {
      alert("Keep file size below 2MB");
    }
  }

  edit(data){
    this.loading = true;
    data.profile_id = this.id;
    this.dataService.postEditStudent(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //@ts-ignore
        $("#modal-edit").modal("hide");
        this.loadData();


      } else {
        alert(res.msg);
      }

    });
  }

  submitPayment(data){
    this.loading = true;
    data.student_id = this.id;
    data.category_id = this.data.category.id
    this.dataService.postFeePayment(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //@ts-ignore
        $("#modal-new-payment").modal("hide"); $('#formPayment')[0].reset();
        this.loadFeePayments();
        this.selectedFeeSemester = "";
        
        

      } else {
        alert(res.msg);
      }

    });
  }

  autoloadFee(val){
    console.log(this.data.fees, val)
    let item = this.data.fees.find(item => item.semester_id == val);
    this.feeAmount = item.fee;
    this.paidFee = "";
    this.balanceFee = "";

    this.dataService.getFeePaidForSemester(this.data.batch.id, val, this.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.paidFee = data.paid;
        this.balanceFee = data.balance;
      }
      
    } );

  }

  inputReceiptNo(fp){
    let input = prompt("Enter Receipt No.", "");

    if(input.length > 0){
      let data = {
        id:fp.id,
        receipt_no:input
      }

      this.dataService.postFPReceiptNo(data).subscribe( (res:any = {status:0, msg:""}) => {
        
        if(res.status == 0) {
          this.loadFeePayments();
          
        } else {
          alert(res.msg);
        }
      
      });
    }
  }

  selectView(view){
    this.selectedView = view;
    if(view == 'score'){
      this.loadScore();
    }
  }

  selectSem(sem){
    this.selectedSem = sem.id;
    this.loadScore();
  }

  loadScore(){
    this.dataService.getStudentExamData(this.id, this.selectedSem).subscribe( (data: any )=> {
      if(data.status == 0){
        this.examData = data;
        
      }
      
    } );
  }

  
  

}
