import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-exam-semester-score',
  templateUrl: './exam-semester-score.component.html',
  styleUrls: ['./exam-semester-score.component.scss']
})
export class ExamSemesterScoreComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  batchId:any = 0;
  semesterId:any = 0; 
  data:any = {data:[]};

  ngOnInit(): void {
    this.batchId = this.route.snapshot.paramMap.get('batchId');
    this.semesterId = this.route.snapshot.paramMap.get('semesterId');

    this.loadData();
  }

  loadData(){
    this.dataService.getExamTotalScore(this.batchId, this.semesterId).subscribe( (data: any )=> {
      console.log(data);
      if(data.status == 0){
        this.data = data;
        console.log(data);
      }
    } );
  }

}
