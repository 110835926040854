import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router) { }
  key:any = null;
  role_id:any = 0;
  loading:any = false;
  mainLogin:any = false;
  ngOnInit(): void {

    this.key = localStorage.getItem('key');
    this.role_id = localStorage.getItem('role_id');

    if (this.key !== null) {
      //Redirect to home;
      this.navDefaultPage()
      this.router.navigate(['/dashboard']);
      
    }

  }

  navDefaultPage(){
    this.dataService.getDefaultPage().subscribe( (data: any )=> {
      if(data.status == 0){
        if(data.default_page != 0){
          this.router.navigate([data.default_page]);
        } else {
          this.router.navigate(['/dashboard']);
        }
      } else {
          this.router.navigate(['/dashboard']);
        }
    } );
  }

  login(data){
    this.loading = true;
    const loginObservable = this.mainLogin ? this.dataService.mainLogin(data) : this.dataService.login(data);
    loginObservable.subscribe( (res:any) => {
      this.loading = false;
      console.log(res);
      if(res.status == 0) {
        localStorage.setItem( "key", res.key);
        localStorage.setItem("role_id", res.role_id);

        if (localStorage.getItem('key') == res.key ) {
          if(res.default_page != 0){
            this.router.navigate([res.default_page]);
          } else {
            this.router.navigate(['/dashboard']);
          }
          
        }

      } else {
        alert(res.msg);
      }

    });

  }

  toggleMainLogin(){
    this.mainLogin = !this.mainLogin;
  }

}
