<div class="page">
    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-1 pt-3 text-center">
                <img *ngIf="photo_url == 0" src="assets/img/user-placeholder.png" alt="">
                <img *ngIf="photo_url != 0" [src]="photo_url" alt="">
                <small>
                    <a href="#modal-upload-photo" data-toggle="modal">Upload</a>
                </small>
            </div>
            <div class="col-10">
                <h3 class="">{{data.name}} <small>({{data.user_name}})</small> </h3>
                <h6>Role: {{data.role}}</h6>

                <button class="btn btn-sm btn-action" routerLink="/dashboard/staff-details/">View Faculty Details</button>
            </div>
        </div>

        <div class="page-body">
            <h4>Settings</h4>
                    <hr>
            <div class="row">
                <div class="col-md-6">
                    

                    <div class="row mb-4">
                        <div class="col-md-2 pt-2">Name</div>
                        <div class="col-md-8">
                            <input type="text" [(ngModel)]="data.name" class="form-control">
                        </div>
                        <div class="col-md-2">
                            <button *ngIf="!updatingName" class="btn btn-sm btn-outline-dark" (click)="updateName()">Update</button>
                            <i class="fa-solid fa-spinner fa-spin" *ngIf="updatingName"></i>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2 pt-2">Username</div>
                        <div class="col-md-8">
                            <input type="text" [(ngModel)]="data.user_name" class="form-control">
                        </div>
                        <div class="col-md-2">
                            <button *ngIf="!updatingUserName" class="btn btn-sm btn-outline-dark" (click)="updateUsername()">Update</button>
                            <i class="fa-solid fa-spinner fa-spin" *ngIf="updatingUserName"></i>
                        </div>
                    </div>


                </div>

                <div class="col-md-6">
                    <h6 class="mb-4">Change password</h6>

                    <form #fpwd="ngForm" (ngSubmit)="submitPassword(fpwd.value)" id="formPassword" ngNativeValidate>
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Current password</label>
                          <input type="password" class="form-control" id="exampleFormControlInput1" placeholder="Current password" ngModel name="current_password" required>
                        </div>
                        <div class="form-group">
                          <label for="exampleFormControlInput1">New password</label>
                          <input type="password" class="form-control" id="exampleFormControlInput1" placeholder="New password" ngModel name="new_password" required>
                        </div>
                        <div class="form-group">
                          <label for="exampleFormControlTextarea1">Repeat password</label>
                          <input type="password" class="form-control" id="exampleFormControlInput1" placeholder="Repeat password" ngModel name="cnew_password" required>
                        </div>
                        <button form="formPassword" id="btn-pwd" class="btn btn-outline-dark">Submit</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</div>


<!-- modals -->

<div class="modal fade" id="modal-upload-photo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Upload Photo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form #fphoto="ngForm" (ngSubmit)="submitPhoto(fphoto.value)" id="formPhoto" ngNativeValidate>
                
                <div class="form-group">
                    <label class="control-label">Select photo</label>
                    <div class="col-12">
                      <input type="file" class="form-control" name="file" (change)="onFileSelect($event)" accept=".jpg, .jpeg, .png, .gif, .webp" required/>
                      
                    </div>
                </div>

                <p class="text-center size-warning" *ngIf="uploadSize != ''">
                    File size is {{uploadSize}}. 
                    <ng-container *ngIf="uploadFile.size >= 2097152">
                      You can't upload files greater than 50 MB
                    </ng-container>
                </p>

                <p class="text-center" *ngIf="mediaUrl != null && mediaUrl != ''">
                    <img *ngIf="mediaType == 'image'" class="img-fluid img-thumbnail" [src]="mediaUrl"/>
        
                </p>
                
            </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!uploading" form="formPhoto" class="btn btn-action">Upload</button>
          <span class="spinner" *ngIf="uploading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>