import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-student-form',
  templateUrl: './student-form.component.html',
  styleUrls: ['./student-form.component.scss']
})
export class StudentFormComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  studentId:any = 0;
  batchId:any = 0;
  data:any = {
    student_id:"",
    university_id:"",
    category_id:"",
    name:"",
    address:"",
    dob:"",
    phone:"",
    email:"",
    guardian_name:"",
    guardian_phone:"",
    guardian_rel:"",
    pincode:"",
    country:"",
    local_body:"",
    apl_bpl:"",
    urban_rural:"",
    community:"",
    religion:"",
    caste:"",
    blood_group:"",
    aadhaar_no:"",
    mother_name:"",
    mother_occupation:"",
    mother_phone:"",
    mother_email:"",
    father_name:"",
    father_occupation:"",
    father_phone:"",
    father_email:"",
    has_disability:0,
    disability_description:"",
    require_hostel:0,
    sex:""
    
  }

  feeCategories:any = [];
  loading:any = false;
  titleVar:any = ""

  ngOnInit(): void {
    this.studentId = (this.route.snapshot.paramMap.get('studentId') != null) ? this.route.snapshot.paramMap.get('studentId') : 0 ;
    this.batchId = (this.route.snapshot.paramMap.get('batchId') != null) ? this.route.snapshot.paramMap.get('batchId') : 0 ;
    console.log(this.studentId, this.batchId);
    
    this.loadFeeCategories();
    if(this.studentId != 0){
      this.loadFullProfile();
      this.titleVar = "Edit";
    } else {
      this.titleVar = "Add New";
    }
  }

  loadFullProfile(){
    this.dataService.getStudentData(this.studentId).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data.profile;
      }
      
    } );
  }

  loadFeeCategories(){
    this.dataService.getFeeCategories().subscribe( (data: any )=> {
      this.feeCategories = data;
      
    } );
  }

  submitStudent(){
    this.loading = true;
    let data = {
      student_id:this.studentId,
      batch_id:this.batchId,
      profile:this.data
    }

    this.dataService.postUpdateStudent(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        this.router.navigate(['/dashboard/student/'+res.id]);

      } else {
        alert(res.msg);
      }

    });

    
    
  }

  setField(field, val){
    this.data[field] = val;
  }

}
