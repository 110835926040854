import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-industrial-visits',
  templateUrl: './industrial-visits.component.html',
  styleUrls: ['./industrial-visits.component.scss']
})
export class IndustrialVisitsComponent implements OnInit {

  constructor(private dataService: DataService) { }
  loading:any = false;
  data:any = {list:[], count:0}
  limit:any = 30;
  offset:any = 0;
  ivSemester:any = "";
  batches:any = [];

  ngOnInit(): void {
    this.loadData();
    this.loadBatches();
  }

  loadData(){
    this.dataService.getIVList(this.limit, this.offset).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
      }
    } );
  }

  loadBatches(){
    this.dataService.getBatches().subscribe( (data: any )=> {
      if(data.status == 0){
        this.batches = data.list;
      }
      
    } );
  }

  setSemester(batchId){
    console.log(this.batches);
    let batch = this.batches.find(batch => batch.id == batchId);
    this.ivSemester = batch.current_semester;
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadData();

  }

  newIV(data){
    this.loading = true;
    this.dataService.postNewIV(data).subscribe( (res:any) => {
      this.loading = false;
      if(res.status == 0) {
        //@ts-ignore
        $("#modal-new-iv").modal("hide");
        this.loadData();


      } else {
        alert(res.msg);
      }

    });
  }

}
