import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {
    id:0,
    photo_url:0
  }
  photo_url:any = 0;
  updatingName:any = false;
  updatingUserName:any = false;
  updatingPassword:any = false;
  
  uploading:any = false;
  uploadFile:any;
  uploadSize:any = "";
  mediaUrl:any = null;
  mediaType:any = null;


  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getUserProfile().subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
        if(data.photo_url != null && data.photo_url != ""){
          this.loadUserPhoto();
        }
      }
      
    } );
  }

  loadUserPhoto(){
    this.dataService.getUserPhoto().subscribe( (data: any )=> {
      if(data.status == 0){
        this.photo_url = data.photo_url;
        
      }
      
    } );
  }

  updateName(){
    let data = {
      field:"name",
      value: this.data.name
    }

    this.updatingName = true;

    this.dataService.postUpdateProfileField(data).subscribe( (res:any) => {
      this.updatingName = false;
      if(res.status == 0) {
        //
      } else {
        alert(res.msg);
      }

    });
  }

  updateUsername(){
    let data = {
      field:"user_name",
      value: this.data.user_name
    }
    this.updatingUserName = true;
    this.dataService.postUpdateProfileField(data).subscribe( (res:any) => {
      this.updatingUserName = false;
      if(res.status == 0) {
        //
      } else {
        alert(res.msg);
      }

    });
  }

  submitPassword(data) {
  	if(data.new_password === data.cnew_password) {

      this.updatingPassword = true;

  		this.dataService.postPassword(data).subscribe( (res:any) => {
        this.updatingPassword = false;
    		if(res.status == 0) {
          alert(res.msg);
          
        } else {
          alert(res.msg);
        }
      });
    		
    	} else {
  		  alert("New password doesn't match.");
  	  }

  }


  onFileSelect(e){
    this.uploadFile = e.target.files[0];
    

    let uploadSize = "";
    if(this.uploadFile.size > 1000 && this.uploadFile.size < 1000000 ){
      uploadSize = (this.uploadFile.size / 1000).toFixed(1) + " KB";

    } else if(this.uploadFile.size >= 1000000){
      uploadSize = (this.uploadFile.size / 1000000).toFixed(1) + " MB";
    }
     else {
      uploadSize = this.uploadFile.size + " Bytes";
    }

    this.uploadSize = uploadSize;

    //media preview
    const mimeType = this.uploadFile.type;

    if (mimeType.match(/image\/*/) !== null) {
      //image
      this.mediaType = "image";
      
    }else if (mimeType.match(/video\/*/) !== null) {
      //video
      this.mediaType = mimeType;
      
    } else {
      //
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.uploadFile); 
    reader.onload = (_event) => { 
        this.mediaUrl = reader.result; 
    }
    

  }

  submitPhoto(data){
    if(this.uploadFile.size < 2097152){
      const formData = new FormData();
      formData.append('file', this.uploadFile, this.uploadFile.name);
      this.uploading = true;

      this.dataService.postFacultyPhoto(formData).subscribe( (res:any = {status:0, msg:""}) => {
        this.uploading = false;
        if(res.status == 0) {
          this.loadData();
          //@ts-ignore
          $("#modal-upload-photo").modal("hide");
        } else {
          alert(res.msg);
        }
      
      });

    } else {
      alert("Keep file size below 2MB");
    }
  }

}
